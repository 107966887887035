import classnames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { fetchEditionBuildStatus } from 'state/buildStatus/actions/buildStatusActions';
import { getStorySubtitlesLanguages } from 'state/buildStatus/selectors/buildStatusSelectors';
import * as editionsActions from 'state/editions/actions/editionsActions';
import { editionIsReadOnly } from 'state/editions/selectors/editionsSelectors';
import { isSubtitlesMultiLanguageEnabled } from 'state/features/selectors/featuresSelectors';
import * as modalsActions from 'state/modals/actions/modalsActions';
import {
  hasAnyVideoSnap,
  isEditionVideoOnly,
} from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import {
  deleteStorySubtitles,
  setSubtitlesLanguage,
  downloadStorySubtitles,
} from 'state/subtitles/actions/subtitlesActions';
import { getSubtitlesActiveLanguageCode } from 'state/subtitles/selectors/subtitlesSelectors';

import { DropzoneType, UploadPurpose } from 'config/constants';
import { getDisplayMessageForLanguageCode } from 'constants/subtitles';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import * as grapheneUtils from 'utils/grapheneUtils';

import SDSCustomModal from 'views/common/components/SDSCustomModal/SDSCustomModal';
import SDSLabel from 'views/common/components/SDSLabel/SDSLabel';
import SubtitlesLanguagesDropdown from 'views/common/components/SubtitlesPreview/components/SubtitlesLanguagesDropdown/SubtitlesLanguagesDropdown';
import MultiLanguageSubtitlesManager from 'views/subtitles/MultiLanguageSubtitlesManager/MultiLanguageSubtitlesManager';

import DeleteSubtitlesButton from './DeleteSubtitlesButton/DeleteSubtitlesButton';
import DownloadSubtitlesButton from './DownloadSubtitlesButton/DownloadSubtitlesButton';
import style from './ManageSubtitlesModal.scss';

import type { EditionID } from 'types/editionID';

type OwnProps = {
  modalId: string;
  options: {
    editionId: EditionID;
  };
};

type OwnState = {
  deleting: boolean;
  downloading: boolean;
};

export const mapStateToProps = (state: State, props: OwnProps) => {
  const storyId = props.options.editionId;
  return {
    selectedLanguage: getSubtitlesActiveLanguageCode(state),
    defaultSubtitlesLanguage: publishersSelectors.getActivePublisherDefaultSubtitlesLanguage(state),
    isEditionVideoOnly: isEditionVideoOnly(state)(storyId),
    isReadOnly: editionIsReadOnly(state)(storyId),
    storySubtitlesLanguages: getStorySubtitlesLanguages(state)(storyId),
    isSubtitlesMultiLanguageEnabled: isSubtitlesMultiLanguageEnabled(state),
    hasAnyVideoSnap: hasAnyVideoSnap(state)(storyId),
  };
};

type StateProps = {
  defaultSubtitlesLanguage: string;
  isEditionVideoOnly: boolean;
  isReadOnly: boolean;
  storySubtitlesLanguages: string[];
  selectedLanguage: string;
  isSubtitlesMultiLanguageEnabled: boolean;
  hasAnyVideoSnap: boolean;
};

const mapDispatchToProps = {
  fetchEditionBuildStatus,
  hideModal: modalsActions.hideModal,
  reloadSnaps: editionsActions.reloadSnaps,
  deleteSubtitles: deleteStorySubtitles,
  setSubtitlesLanguage,
  downloadSubtitles: downloadStorySubtitles,
};

type DispatchProps = {
  fetchEditionBuildStatus: typeof fetchEditionBuildStatus;
  hideModal: typeof modalsActions.hideModal;
  reloadSnaps: typeof editionsActions.reloadSnaps;
  deleteSubtitles: (b: EditionID, a: string) => Promise<void>;
  setSubtitlesLanguage: typeof setSubtitlesLanguage;
  downloadSubtitles: (b: EditionID, a: string) => Promise<void>;
};

type Props = OwnProps & DispatchProps & StateProps;

export class ManageSubtitlesModal extends React.PureComponent<Props, OwnState> {
  state = {
    deleting: false,
    downloading: false,
  };

  mounted: boolean = false;

  componentDidMount(): void {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  executeIfMounted(callback: () => void) {
    if (this.mounted) {
      callback();
    }
  }

  setStateIfMounted(partialState: Partial<OwnState>) {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Partial<State>' is not assignabl... Remove this comment to see the full error message
    this.executeIfMounted(() => this.setState(partialState));
  }

  closeModal = () => {
    this.executeIfMounted(() => {
      this.props.hideModal(this.props.modalId);
    });
  };

  reloadSnapsAndStatus = () => {
    const { editionId } = this.props.options;
    return Promise.all([
      this.props.reloadSnaps(editionId),
      this.props.fetchEditionBuildStatus({ editionId, noBailout: true }),
    ]);
  };

  handleDeleteSubtitle = (languageCode: string) => {
    const { deleteSubtitles, options } = this.props;

    grapheneUtils.incrementCounter('subtitles', {
      click: 'story_delete',
      language: languageCode,
    });
    this.setState({ deleting: true });
    deleteSubtitles(options.editionId, languageCode)
      .then(() => this.reloadSnapsAndStatus())
      .finally(() => this.setStateIfMounted({ deleting: false }));
  };

  handleDownloadSubtitles = (languageCode: string) => {
    const { downloadSubtitles, options } = this.props;

    this.setState({ downloading: true });
    downloadSubtitles(options.editionId, languageCode).finally(() => this.setStateIfMounted({ downloading: false }));
  };

  renderSubtitleRow = (languageCode: string) => {
    return (
      <div key={languageCode} className={classnames(style.flexRow, style.deleteButtonDivider)}>
        <span>{getDisplayMessageForLanguageCode(languageCode)}</span>
        <span className={style.flexRow}>
          <span className={style.subtitlesModalButton}>
            <DeleteSubtitlesButton
              languageCode={languageCode}
              onDelete={this.handleDeleteSubtitle}
              isDeleting={this.state.deleting}
            />
          </span>
          <span className={style.subtitlesModalButton}>
            <DownloadSubtitlesButton
              languageCode={languageCode}
              onDownload={this.handleDownloadSubtitles}
              isDownloading={this.state.downloading}
            />
          </span>
        </span>
      </div>
    );
  };

  renderDeleteSubtitles() {
    const { storySubtitlesLanguages } = this.props;

    if (storySubtitlesLanguages.length === 0) {
      return null;
    }

    return (
      <>
        <span className={style.sectionTitle}>
          <FormattedMessage
            id="SubtitlesModal.delete.title"
            description="Title of the prompt displayed when we try to delete subtitles."
            defaultMessage="Existing Subtitles"
          />
        </span>
        <div className={style.subtitlesColumn}>{storySubtitlesLanguages.map(this.renderSubtitleRow)}</div>
      </>
    );
  }

  renderSubtitleTrackDropdown = () => {
    return (
      <SDSLabel
        withoutDefaultMargin
        labelTitle={
          <FormattedMessage
            id="subtitles-subtitle-track-dropdown-label"
            description="Label for subtitle track dropdown"
            defaultMessage="Subtitle track"
          />
        }
      >
        <SubtitlesLanguagesDropdown
          defaultLanguage={this.props.defaultSubtitlesLanguage}
          selectedLanguage={this.props.selectedLanguage}
          existingLanguages={this.props.storySubtitlesLanguages || []}
          onSelectedChange={this.props.setSubtitlesLanguage}
          disabled={!this.props.isSubtitlesMultiLanguageEnabled}
        />
      </SDSLabel>
    );
  };

  render() {
    return (
      <SDSCustomModal
        visible
        onClose={this.closeModal}
        title={
          <FormattedMessage
            id="subtitles-manage-subtitles-modal-title"
            description="Modal title for adding subtitles"
            defaultMessage="Subtitles"
          />
        }
        footer={null}
      >
        <div className={style.modalContainer} data-test="uploadSubTitlesModal.container">
          <MultiLanguageSubtitlesManager
            hideHeader
            purpose={UploadPurpose.EDITION_SUBTITLES}
            dropzoneType={DropzoneType.EDITION_SUBTITLES}
            isReadOnly={this.props.isReadOnly}
            dataTestPrefix="ManageSubtitlesModal"
            showInfo
            hasSubtitles={this.props.storySubtitlesLanguages.includes(this.props.selectedLanguage)}
            subtitlesDropdown={this.renderSubtitleTrackDropdown()}
            onGenerationFinished={this.closeModal}
            onUploadFinished={this.reloadSnapsAndStatus}
            generateDisabled={!this.props.hasAnyVideoSnap}
            uploadDisabled={!this.props.isEditionVideoOnly}
          />
          {this.renderDeleteSubtitles()}
        </div>
      </SDSCustomModal>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(ManageSubtitlesModal);
