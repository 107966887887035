import React from 'react';

import * as authSelectors from 'state/auth/selectors/authSelectors';
import * as userSelectors from 'state/user/selectors/userSelectors';

import { AuthType } from 'config/constants';
import * as creativeSuiteAPI from 'utils/apis/creativeSuiteAPI';
import { intlConnect } from 'utils/connectUtils';
import * as locationUtils from 'utils/locationUtils';

import type { PublisherID } from 'types/publishers';
import type { State } from 'types/rootState';

type Props = {
  publisherId: PublisherID;
  isSnapSSO: boolean;
};

const mapStateToProps = (state: State): Props => {
  const authType = authSelectors.getAuthType(state);
  return {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
    publisherId: userSelectors.getActivePublisherId(state),
    isSnapSSO: authType === AuthType.SNAPCHAT,
  };
};

export class CreativeSuiteTemplates extends React.PureComponent<Props> {
  render() {
    const { isSnapSSO, publisherId } = this.props;

    const templatesUrl = creativeSuiteAPI.creative.embedTemplateMode(isSnapSSO)({
      publisherId,
      ref: locationUtils.getOriginHref(),
    });
    return (
      <iframe
        src={templatesUrl}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'boolean |... Remove this comment to see the full error message
        seamless="seamless"
        frameBorder="0"
        width="100%"
        height="100%"
      />
    );
  }
}

export default intlConnect(mapStateToProps, null)(CreativeSuiteTemplates);
