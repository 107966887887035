import is from 'is_js';
import { get, values, keys, sumBy, memoize } from 'lodash';
import moment from 'moment-timezone';
import { createSelector as s } from 'reselect';

import { AnalyticsState } from 'state/analytics/analyticsState';
import { createKeySelector } from 'state/common/selectorFactories';

import {
  EMPTY_OBJECT,
  EMPTY_ARRAY,
  ANALYTICS_INSIGHTS_TOP_PERFORMING_TILES_DEFAULT,
  ANALYTICS_INSIGHTS_TOP_PERFORMING_STORIES_DEFAULT,
  PT_TIME_ZONE,
} from 'config/constants';
import { DEFAULT_EDITION_DATE_RANGE, DEFAULT_SERVICE_STATUS } from 'constants/analytics';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import { fillMissingDates } from 'views/analytics/utils/chartConfigs';

import type {
  EditionDateRange,
  AnalyticsStatsTypeEnum,
  EmptyDemographicsObject,
  SnapMetrics,
  StoryMetricResults,
} from 'types/analytics';
import { AnalyticsStatsType, SnapMetricResults } from 'types/analytics';
import type { State } from 'types/rootState';

export const ageGroupMessageIds = [
  'demographics-age-13-to-17',
  'demographics-age-18-to-24',
  'demographics-age-25-to-34',
  'demographics-age-35-plus',
  'demographics-unknown',
];

const SUBSCRIBER_VIEWERS_DATE_CUTOFF = new Date('2020-11-12');
const deserializeDate = memoize(str => moment.tz(str, 'YYYY-MM-DD', PT_TIME_ZONE).valueOf());
const getAnalytics = (state: State) => state.analytics || EMPTY_OBJECT;
export const getAnalyticsStatsType = createKeySelector(getAnalytics, 'analyticsStatsType', AnalyticsStatsType.ALL);
export const getSnaps = s(
  getAnalytics,
  getAnalyticsStatsType,
  (analyticsData: any, analyticsStatsType: AnalyticsStatsTypeEnum) => {
    return analyticsData?.snaps?.[analyticsStatsType] || EMPTY_OBJECT;
  }
);
export const getPublisherEditionHistoryInfo = createKeySelector(
  getAnalytics,
  'publisherEditionHistoryInfo',
  EMPTY_OBJECT
);
export const getStory = s(
  getAnalytics,
  getAnalyticsStatsType,
  (analyticsData: any, analyticsStatsType: AnalyticsStatsTypeEnum) => {
    return analyticsData?.edition?.[analyticsStatsType] || EMPTY_OBJECT;
  }
);
export const getDaily = createKeySelector(getAnalytics, 'daily', null);
export const getInsights = createKeySelector(getAnalytics, 'insights', {
  slcs: EMPTY_ARRAY,
  trafficSources: EMPTY_ARRAY,
  topPerformingTiles: ANALYTICS_INSIGHTS_TOP_PERFORMING_TILES_DEFAULT,
  topPerformingStories: ANALYTICS_INSIGHTS_TOP_PERFORMING_STORIES_DEFAULT,
});
export const getCountryCodes = createKeySelector(getAnalytics, 'countryCodes', EMPTY_ARRAY);
export const getServiceStatus = createKeySelector(getAnalytics, 'serviceStatus', DEFAULT_SERVICE_STATUS);
export const getEditionDateRange = createKeySelector(getAnalytics, 'editionDateRange', DEFAULT_EDITION_DATE_RANGE);
export const getEditionList = createKeySelector(getAnalytics, 'editionList', EMPTY_ARRAY);
export const isStoryReachAffectedByModeration = s(getStory, storyAnalytics => (storyId: any) => {
  if (!storyId || !storyAnalytics.editionMetrics) {
    return false;
  }
  return get(storyAnalytics.editionMetrics[storyId], 'reachAffectedByModeration', false);
});
export const getStoryMetricsMaps = s(getAnalytics, (storyAnalytics: AnalyticsState) => {
  return {
    [AnalyticsStatsType.ALL]: Object.values(storyAnalytics?.edition?.[AnalyticsStatsType.ALL]?.editionMetrics || {})[0],
    [AnalyticsStatsType.PAID]: Object.values(
      storyAnalytics?.edition?.[AnalyticsStatsType.PAID]?.editionMetrics || {}
    )[0],
  };
});

export const isShowingAllTimeStats = s(getEditionDateRange, (dateRange: EditionDateRange) => {
  return !dateRange.from && !dateRange.to;
});

export const isShowingSingleDayStats = s(getEditionDateRange, (dateRange: EditionDateRange) => {
  return dateRange.from ? dateRange.from.isSame(dateRange.to) : false;
});

const getDailyMetricsMap = s(getDaily, dailyAnalytics => {
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  if (!dailyAnalytics || !dailyAnalytics.dailyMetrics || Object.keys(dailyAnalytics.dailyMetrics).length === 0) {
    return {};
  }
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  return dailyAnalytics.dailyMetrics;
});
export const getStoryEditionMetricsById = s(getStory, storyAnalytics => (editionId: any) => {
  if (!storyAnalytics || !storyAnalytics.editionMetrics) {
    return {};
  }
  return storyAnalytics.editionMetrics[editionId];
});
export const getDemographicsMaps = (storyMetrics: StoryMetricResults | undefined) => {
  const age13To17Map: EmptyDemographicsObject = {};
  const age18To24Map: EmptyDemographicsObject = {};
  const age25To34Map: EmptyDemographicsObject = {};
  const age35PlusMap: EmptyDemographicsObject = {};
  const ageUnknownMap: EmptyDemographicsObject = {};

  age13To17Map.male = getValue(storyMetrics?.uniqueMale1317Viewers);
  age18To24Map.male = getValue(storyMetrics?.uniqueMale1820Viewers) + getValue(storyMetrics?.uniqueMale2124Viewers);
  age25To34Map.male = getValue(storyMetrics?.uniqueMale2534Viewers);
  age35PlusMap.male = getValue(storyMetrics?.uniqueMale35PlusViewers);
  ageUnknownMap.male = getValue(storyMetrics?.uniqueMaleUnknownViewers);
  age13To17Map.female = getValue(storyMetrics?.uniqueFemale1317Viewers);
  age18To24Map.female =
    getValue(storyMetrics?.uniqueFemale1820Viewers) + getValue(storyMetrics?.uniqueFemale2124Viewers);
  age25To34Map.female = getValue(storyMetrics?.uniqueFemale2534Viewers);
  age35PlusMap.female = getValue(storyMetrics?.uniqueFemale35PlusViewers);
  ageUnknownMap.female = getValue(storyMetrics?.uniqueFemaleUnknownViewers);
  age13To17Map.unknown = getValue(storyMetrics?.uniqueUnknown1317Viewers);
  age18To24Map.unknown =
    getValue(storyMetrics?.uniqueUnknown1820Viewers) + getValue(storyMetrics?.uniqueUnknown2124Viewers);
  age25To34Map.unknown = getValue(storyMetrics?.uniqueUnknown2534Viewers);
  age35PlusMap.unknown = getValue(storyMetrics?.uniqueUnknown35PlusViewers);
  ageUnknownMap.unknown = getValue(storyMetrics?.uniqueUnknownUnknownViewers);

  return [age13To17Map, age18To24Map, age25To34Map, age35PlusMap, ageUnknownMap];
};

export const prefixWithPaid = (snapObject: any) => {
  const updatedObject: any = {};
  Object.keys(snapObject).forEach(k => {
    updatedObject[`paid${k[0]?.toUpperCase() + k.slice(1)}`] = snapObject[k];
  });
  return updatedObject;
};

export const getEditionUniqueDau = s(
  getAnalytics,
  isShowingAllTimeStats,
  (storyAnalytics: AnalyticsState, isShowingStatsForAllTime: boolean) => {
    const statsObject = isShowingStatsForAllTime ? storyAnalytics?.edition : storyAnalytics.editionDaily;

    return {
      paidUniqueDau: Object.values(statsObject?.[AnalyticsStatsType.PAID]?.editionMetrics || {})?.[0]?.uniqueDau,
      uniqueDau: Object.values(statsObject?.[AnalyticsStatsType.ALL]?.editionMetrics || {})[0]?.uniqueDau,
    };
  }
);

export const getEditionSubscriberCount = s(
  getAnalytics,
  isShowingAllTimeStats,
  (storyAnalytics: AnalyticsState, isShowingStatsForAllTime: boolean) => {
    const statsObject = isShowingStatsForAllTime ? storyAnalytics?.edition : storyAnalytics.editionDaily;

    return {
      paidSubscriberCount: Object.values(statsObject?.[AnalyticsStatsType.PAID]?.editionMetrics || {})?.[0]
        ?.publisherSubscribeCount,
      subscriberCount: Object.values(statsObject?.[AnalyticsStatsType.ALL]?.editionMetrics || {})[0]
        ?.publisherSubscribeCount,
    };
  }
);

export const getStoryDemographicsMetrics = s(getStoryMetricsMaps, storyMetrics => (localeId: any) => {
  if (!storyMetrics?.[AnalyticsStatsType.ALL]) {
    return EMPTY_ARRAY;
  }

  const ageGroups = getDemographicsMaps(storyMetrics?.ALL);

  const paidAgeGroups = getDemographicsMaps(storyMetrics?.[AnalyticsStatsType.PAID]);

  return ageGroupMessageIds.map((id: string, index: number) => {
    const name = getMessageFromId(id, {});

    return {
      name,
      male: ageGroups[index]?.male,
      paidMale: paidAgeGroups[index]?.male,
      female: ageGroups[index]?.female,
      paidFemale: paidAgeGroups[index]?.female,
      unknown: ageGroups[index]?.unknown,
      paidUnknown: paidAgeGroups[index]?.unknown,
    };
  });
});

export const getSnapValues = (snap: SnapMetricResults | undefined, localeId: string) => {
  if (!snap) {
    return {};
  }

  const snapId = snap.snap_id;
  const segmentId = snap?.segment_id;
  const mediaId = snap?.media_id;
  const mediaType = snap?.media_type?.toLowerCase();
  const name = getSnapName(localeId, snap?.snap_index);
  const { poll } = snap;
  // metrics
  const longformTimeViewed = getValue(snap?.longform_time_viewed);
  const uniqueLongformViewers = getValue(snap?.unique_longform_viewers);
  const topsnapTimeViewed = getValue(snap?.top_snap_time_viewed);
  const uniques = getValue(snap?.unique_viewers);
  const uniqueSubscriberViewers = getValue(snap?.unique_subscriber_viewers);
  const uniqueNonSubscriberViewers = uniques - uniqueSubscriberViewers;
  const attachmentTimeSpent = uniqueLongformViewers === 0 ? 0 : getValue(longformTimeViewed) / uniqueLongformViewers;
  const completionRate = getValue(snap?.completion_rate);
  const conversion = getValue(snap?.longform_conversion_rate);
  const dropOffRates = getValue(snap?.snapExitRate);
  const runTime = getValue(snap?.snap_time_ms) / 1000;
  const screenshots = getValue(snap?.total_screenshot_count);
  const shares = getValue(snap?.total_share_count);
  const timeSpent = uniques === 0 ? 0 : getValue(topsnapTimeViewed) / uniques;
  return {
    snapId,
    attachmentTimeSpent,
    completionRate,
    conversion,
    dropOffRates,
    mediaId,
    mediaType,
    name,
    poll,
    runTime,
    screenshots,
    segmentId,
    shares,
    timeSpent,
    uniques,
    uniqueSubscriberViewers,
    uniqueNonSubscriberViewers,
  };
};

export const getStorySnapMetrics = s(getAnalytics, (analytics: AnalyticsState) => (localeId: string) => {
  if (!analytics.snaps) {
    return EMPTY_ARRAY;
  }

  const organicSnaps: any = analytics?.snaps?.[AnalyticsStatsType.ALL] || {};
  const paidSnaps: any = analytics?.snaps?.[AnalyticsStatsType.PAID] || {};

  const orderedSnapIdList = Object.keys(organicSnaps)
    .filter(snapId => organicSnaps[snapId] && is.number(parseInt(organicSnaps[snapId].snap_index, 10)))
    .sort((snapId1, snapId2) => organicSnaps[snapId1]?.snap_index - organicSnaps[snapId2]?.snap_index);
  return orderedSnapIdList.map(id => {
    return {
      ...getSnapValues(organicSnaps[id], localeId),
      ...prefixWithPaid(getSnapValues(paidSnaps[id], localeId)),
    };
  });
});

export const getStoryRunTime = s(getSnaps, (snaps: SnapMetrics) => {
  if (!snaps) {
    return 0;
  }
  return Math.floor(sumBy(values(snaps), 'snap_time_ms') / 1000);
});
export const getStorySnapCount = s(getSnaps, (snaps: SnapMetrics) => {
  if (!snaps) {
    return 0;
  }
  return keys(snaps).length;
});
export const getDailyMetrics = s(getDailyMetricsMap, dailyMetricsMap => (localeId: string) => {
  if (!dailyMetricsMap) {
    return EMPTY_ARRAY;
  }
  const dates = fillMissingDates(Object.keys(dailyMetricsMap));
  return dates.map((date: any) => {
    const metrics = dailyMetricsMap[date] || {};
    // audience
    const uniqueDau = getValue(metrics.unique_dau);
    let uniqueSubscriberViewers;
    let uniqueNonSubscriberViewers;
    const dateObj = new Date(date);
    // Prior to 2020/11/12 there was an issue in the unified event pipeline that
    // did not correctly record subscriber vs non-subscriber view events. Therefore
    // we will suppress the data for dates older than that.
    if (dateObj >= SUBSCRIBER_VIEWERS_DATE_CUTOFF) {
      uniqueSubscriberViewers = getValue(metrics.unique_subscriber_viewers);
      uniqueNonSubscriberViewers = uniqueDau - uniqueSubscriberViewers;
    } else {
      uniqueSubscriberViewers = 0;
      uniqueNonSubscriberViewers = uniqueDau;
    }
    const uniqueDau25 = getValue(metrics.unique_dau_25);
    const uniqueDau50 = getValue(metrics.unique_dau_50);
    const uniqueDau75 = getValue(metrics.unique_dau_75);
    const uniqueMau = getValue(metrics.unique_mau);
    const subscribers = getValue(metrics.subscriber_count);
    const subscribers25 = getValue(metrics.subscriber_count_25);
    const subscribers50 = getValue(metrics.subscriber_count_50);
    const subscribers75 = getValue(metrics.subscriber_count_75);
    const userLoyaltyDau1Day = getValue(metrics.dau_1_day);
    const userLoyaltyDau2Day = getValue(metrics.dau_2_days);
    const userLoyaltyDau34Day = getValue(metrics.dau_3_4_days);
    const userLoyaltyDau567Day = getValue(metrics.dau_5_6_7_days);
    const uniqueMaleViewers = getValue(metrics.unique_male_viewers);
    const uniqueFemaleViewers = getValue(metrics.unique_female_viewers);
    const uniqueUnknownViewers = getValue(metrics.unique_unknown_gender_viewers);
    const age13to17Viewers = getValue(metrics.unique_13_17_viewers);
    const age18to24Viewers = getValue(metrics.unique_18_24_viewers);
    const age25to34Viewers = getValue(metrics.unique_25_34_viewers);
    const age35PlusViewers = getValue(metrics.unique_35_plus_viewers);
    const ageUnknownViewers = getValue(metrics.unique_unknown_age_viewers);
    // behaviour
    const uniqueTopsnapViewsPerUser = getValue(metrics.unique_top_snap_view_per_user);
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    const timeSpent = parseFloat(getValue(metrics.total_time_viewed)) / parseFloat(uniqueDau);
    const avgTimeSpent25 = getValue(metrics.avg_time_spent_25);
    const avgTimeSpent50 = getValue(metrics.avg_time_spent_50);
    const avgTimeSpent75 = getValue(metrics.avg_time_spent_75);
    const attachmentConversion = getValue(metrics.longform_avg_conversion);
    const avgAttachmentConversion25 = getValue(metrics.longform_avg_conversion_25);
    const avgAttachmentConversion50 = getValue(metrics.longform_avg_conversion_50);
    const avgAttachmentConversion75 = getValue(metrics.longform_avg_conversion_75);
    const topsnapViewCount = getValue(metrics.top_snap_view_count);
    const longformVideoViewCount = getValue(metrics.longform_video_view_count);
    const longformTextViewCount = getValue(metrics.longform_text_view_count);
    const screenshotCount = getValue(metrics.total_screenshot_count);
    const shareCount = getValue(metrics.total_share_count);
    return {
      date: deserializeDate(date),
      uniqueDau,
      uniqueSubscriberViewers,
      uniqueNonSubscriberViewers,
      uniqueDau25,
      uniqueDau50,
      uniqueDau75,
      uniqueMau,
      subscribers,
      subscribers25,
      subscribers50,
      subscribers75,
      userLoyaltyDau1Day,
      userLoyaltyDau2Day,
      userLoyaltyDau34Day,
      userLoyaltyDau567Day,
      uniqueMaleViewers,
      uniqueFemaleViewers,
      uniqueUnknownViewers,
      age13to17Viewers,
      age18to24Viewers,
      age25to34Viewers,
      age35PlusViewers,
      ageUnknownViewers,
      uniqueTopsnapViewsPerUser,
      timeSpent,
      avgTimeSpent25,
      avgTimeSpent50,
      avgTimeSpent75,
      attachmentConversion,
      avgAttachmentConversion25,
      avgAttachmentConversion50,
      avgAttachmentConversion75,
      topsnapViewCount,
      longformVideoViewCount,
      longformTextViewCount,
      screenshotCount,
      shareCount,
    };
  });
});
function getValue(metric: any) {
  const parsedValue = parseFloat(metric);
  return is.not.number(parsedValue) ? 0 : parsedValue;
}
function getSnapName(localeId: any, index: any) {
  return getMessageFromId('snap-index-in-edition', { index });
}
