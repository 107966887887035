import React from 'react';

import { fetchDebugEdition } from 'state/debug/debugActions';
import { debugEditionEndpoint } from 'state/debug/debugApis';

import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { withRouter } from 'utils/routerUtils';

import ClaimGatedView from 'views/common/containers/ClaimGatedView/ClaimGatedView';
import { DebugPage } from 'views/debug/components/DebugPage';
import {
  formatDate,
  transformDiffedAuditRecordsForDebug,
  transformDiffedAuditRecordsForLegible,
  transformToDiffedAuditRecords,
} from 'views/debug/utils/debugPageUtils';

import style from './DebugEditionView.scss';

import type { EditionID } from 'types/editions';
import { Claim } from 'types/permissions';

function transformData({ edition, auditRecords }: any) {
  const diffedAuditRecords = transformToDiffedAuditRecords(auditRecords);
  return [
    { title: 'Edition', subsections: transformEditionInfo(edition) },
    { title: 'Simplified Audit Records', subsections: transformDiffedAuditRecordsForLegible(diffedAuditRecords) },
    { title: 'Debug Audit Records', subsections: transformDiffedAuditRecordsForDebug(diffedAuditRecords) },
  ];
}

function transformEditionInfo(edition: any) {
  const { id, title, createdAt, lastUpdatedAt } = edition;

  return [
    [
      ['Id', id],
      ['Title', title],
      ['Created At', formatDate(createdAt)],
      ['Last Updated At', formatDate(lastUpdatedAt)],
      ['Snaps', formatSnapsArray(edition.snapIds)],
      ['Raw', { type: 'json', object: edition, isExpanded: (keypath: any) => false }],
    ],
  ];
}

function formatSnapsArray(snapIds: any) {
  return snapIds.map((snapId: any) => ({
    type: 'link',
    href: `/v2/debug/snap/${snapId}`,
    label: snapId,
  }));
}

const mapStateToProps = (state: State, ownProps: any) => {
  const { editionId } = ownProps.match.params;
  const { debugEditionsById } = state.debug; // eslint-disable-line no-shadow
  const debugEditionData = debugEditionsById[editionId];

  return {
    sections: debugEditionData && transformData(debugEditionData),
  };
};

const mapDispatchToProps = {
  fetchDebugEdition,
};

type Props = {
  fetchDebugEdition: (editionId: EditionID) => any;
  match: any;
  sections: any;
};

/* eslint-disable react/prop-types */

class DebugEditionView extends React.Component<Props> {
  UNSAFE_componentWillMount() {
    const { props } = this;
    const { editionId } = props.match.params;
    props.fetchDebugEdition(editionId);
  }

  render() {
    const { match, sections } = this.props;
    const { editionId } = match.params;

    return (
      <div className={style.root}>
        <ClaimGatedView requiredClaim={Claim.DEBUG_ENTITY_VIEWER}>
          <DebugPage sections={sections} type="Edition" id={editionId} href={debugEditionEndpoint(editionId)} />
        </ClaimGatedView>
      </div>
    );
  }
}

export default withRouter(intlConnect(mapStateToProps, mapDispatchToProps)(DebugEditionView));
