import { assertArg } from './assertionUtils';

export const NumberSigns = {
  POSITIVE: 'POSITIVE',
  NEGATIVE: 'NEGATIVE',
  ZERO: 'ZERO',
  INVALID: 'INVALID',
};

type Sign = typeof NumberSigns[keyof typeof NumberSigns];

export function approximatelyEqual(a: number, b: number, tolerance: number): boolean {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(a).is.number();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(b).is.number();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(tolerance).is.number();

  return Math.abs(a - b) <= Math.abs(tolerance);
}

export function signOfValue(value: number): Sign {
  if (value > 0) {
    return NumberSigns.POSITIVE;
  }
  if (value < 0) {
    return NumberSigns.NEGATIVE;
  }
  if (value === 0) {
    return NumberSigns.ZERO;
  }
  return NumberSigns.INVALID;
}

export function percentChanged(value: number, previousValue: number): number {
  if (previousValue === 0) {
    return NaN;
  }
  return value / previousValue - 1;
}

export function nanAsZero(value: number): number {
  if (typeof value !== 'number' || Number.isNaN(Number(value))) {
    return 0;
  }
  return value;
}

export function printTwoDecimal(value: number): string {
  return value.toFixed(2);
}
