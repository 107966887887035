import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as userSelectors from 'state/user/selectors/userSelectors';

import { intlConnect } from 'utils/connectUtils';

import SDSCustomModal from 'views/common/components/SDSCustomModal/SDSCustomModal';
import PublisherSettingsTab from 'views/onboarding/containers/SettingsView/tabs/PublisherSettingsTab/PublisherSettingsTab';

import style from './AddPublisherDetailsModal.scss';

import type { PublisherID } from 'types/publishers';
import type { State as RootState } from 'types/rootState';

type StateProps = {
  publisherId: PublisherID;
};
type Props = {
  hideModal: (b: string, a: {}) => void;
  modalId: string;
} & StateProps;
export const mapStateToProps = (state: RootState): StateProps => {
  const publisherId = userSelectors.getActivePublisherId(state);
  return {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
    publisherId,
  };
};
export class AddPublisherDetailsModal extends React.Component<Props> {
  handleClose = async () => {
    this.props.hideModal(this.props.modalId, { committed: false });
  };

  render() {
    return (
      <SDSCustomModal
        visible
        title={
          <FormattedMessage
            id="publisher-details-header"
            defaultMessage="Tell us about yourself"
            description="Header for the page asking for more publisher details"
          />
        }
        footer={null}
        onClose={this.handleClose}
        width={1000}
        isBodyCentered
      >
        <div className={style.subTitle}>
          {
            <FormattedMessage
              id="publisher-details-sub-header-with-end-snap"
              defaultMessage="We need some more information before you can post."
              description="Sub header for the page asking for more publisher details"
            />
          }
        </div>
        <PublisherSettingsTab publisherId={this.props.publisherId} data-test="AddPublisherDetails.Modal.Settings" />
        <div className={style.continueOptions}>
          <a href="https://www.snap.com/en-US/terms/" target="_blank" className={(style as any).tosLink}>
            <FormattedMessage
              id="publisher-details-tou-3"
              defaultMessage="By pressing Save, you agree to our Terms of Use."
              description="Header for the profile section of the page asking for more publisher details"
            />
          </a>
        </div>
      </SDSCustomModal>
    );
  }
}
export default intlConnect(mapStateToProps, null)(AddPublisherDetailsModal);
