import React from 'react';

import * as snapAdminActions from 'state/snapAdmin/actions/snapAdminActions';
import * as snapAdminSelectors from 'state/snapAdmin/selectors/snapAdminSelectors';
import type { User } from 'state/user/userState';

import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { filterEmailDomain, RolesTextForAdminList } from 'utils/userManagementUtils';

import { Spinner, SpinnerLabels, SpinnerSizes } from 'views/common/components/Spinner/Spinner';
import UserManagement from 'views/common/components/UserManagement/UserManagement';

import style from './AdminUserManagement.scss';

const mapStateToProps = (state: State) => ({
  users: snapAdminSelectors.getUsers(state),
  userList: snapAdminSelectors.getUserIdsAlphabeticalByName(state),
  isLoading: snapAdminSelectors.getUsersLoadingCount(state) > 0,
});
const mapDispatchToProps = {
  getUsers: snapAdminActions.getUsers,
  createUser: snapAdminActions.createAndAddRolesToSnapAdminUser,
  editUser: snapAdminActions.editSnapAdminUser,
  deleteUser: snapAdminActions.deleteSnapAdminUser,
};

type AdminUserManagementProps = {
  isLoading: boolean;
  users?: {
    [key: string]: User;
  };
  userList?: string[];
};
export class AdminUserManagement extends React.Component<AdminUserManagementProps> {
  static title = 'User Management';

  static path = '/snap_admin/users';

  UNSAFE_componentWillMount() {
    (this.props as any).getUsers();
  }

  loadingSpinner = () => {
    return (
      <div className={style.noRows}>
        <Spinner loading size={SpinnerSizes.LARGE} message={SpinnerLabels.LOADING} />
      </div>
    );
  };

  render() {
    if (this.props.isLoading) {
      return this.loadingSpinner();
    }
    const emailDomain = filterEmailDomain(this.props.users);
    return (
      <UserManagement
        userList={this.props.userList}
        users={this.props.users}
        emailDomain={emailDomain}
        rolesTextList={RolesTextForAdminList}
        snapAdmin
        editUser={(this.props as any).editUser}
        createUser={(this.props as any).createUser}
        deleteUser={(this.props as any).deleteUser}
      />
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(AdminUserManagement);
