import React from 'react';

import { fetchDebugPublisher } from 'state/debug/debugActions';
import { debugPublisherEndpoint } from 'state/debug/debugApis';
import { getActiveCreator } from 'state/user/selectors/userSelectors';

import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { withRouter } from 'utils/routerUtils';

import ClaimGatedView from 'views/common/containers/ClaimGatedView/ClaimGatedView';
import { DebugPage } from 'views/debug/components/DebugPage';
import { transformDiffedAuditRecordsForDebug, transformToDiffedAuditRecords } from 'views/debug/utils/debugPageUtils';

import style from './DebugEditionView.scss';

import { Claim } from 'types/permissions';
import type { PublisherID } from 'types/publishers';

function transformData({ publisher, auditRecords }: any) {
  return [
    { title: 'Publisher', subsections: transformPublisherInfo(publisher) },
    {
      title: 'Audit Records',
      subsections: transformDiffedAuditRecordsForDebug(transformToDiffedAuditRecords(auditRecords)),
    },
  ];
}

function transformPublisherInfo(publisher: any) {
  const { id, name } = publisher;

  return [
    [
      ['Id', id],
      ['Name', name],
      ['Raw', { type: 'json', object: publisher, isExpanded: (keypath: any) => false }],
    ],
  ];
}

const mapStateToProps = (state: State, ownProps: any) => {
  const publisherId = getActiveCreator(state)?.publisherId;
  const { debugPublishersById } = state.debug; // eslint-disable-line no-shadow
  const debugPublisherData = publisherId ? debugPublishersById[publisherId] : null;

  return {
    publisherId,
    sections: debugPublisherData && transformData(debugPublisherData),
  };
};

const mapDispatchToProps = {
  fetchDebugPublisher,
};

type Props = {
  fetchDebugPublisher: (publisherId: PublisherID) => any;
  publisherId: any;
  sections: any;
};

/* eslint-disable react/prop-types */
class DebugEditionView extends React.Component<Props> {
  render() {
    const { publisherId, sections } = this.props;

    return (
      <div className={style.root}>
        <ClaimGatedView requiredClaim={Claim.DEBUG_ENTITY_VIEWER}>
          <DebugPage sections={sections} type="Publisher" id={publisherId} href={debugPublisherEndpoint(publisherId)} />
        </ClaimGatedView>
      </div>
    );
  }
}

export default withRouter(intlConnect(mapStateToProps, mapDispatchToProps)(DebugEditionView));
