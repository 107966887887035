import is from 'is_js';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as componentsSelectors from 'state/editor/selectors/componentsSelectors';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';

import { UploadFormat, DropzoneType, UploadPurpose } from 'config/constants';
import { AssetID } from 'src/types/assets';
import { ModalId } from 'src/types/modal';
import { State } from 'src/types/rootState';
import { extractSnapIdFromComponentId } from 'utils/componentUtils';
import { intlConnect } from 'utils/connectUtils';

import SDSCustomModal from 'views/common/components/SDSCustomModal/SDSCustomModal';
import MediaUploader from 'views/editor/containers/MediaUploader/MediaUploader';

import style from './UploadImageModal.scss';

export const mapStateToProps = (state: State) => {
  const activeEditionId = editorSelectors.getActiveEditionId(state);
  const activeComponentId = componentsSelectors.getActiveComponentId(state);
  const activeSnapId = activeComponentId ? extractSnapIdFromComponentId(activeComponentId) : null;
  const isReadOnly = activeSnapId
    ? editorSelectors.isLocked(state)({
        snapId: activeSnapId,
      })
    : false;
  return {
    activeEditionId,
    activeSnapId,
    isReadOnly,
  };
};

type OwnProps = {
  modalId: ModalId;
  hideModal: (modalId: ModalId, options?: { assetIds: AssetID[] }) => void;
  options: {
    purpose?: UploadPurpose;
  };
};
type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StateProps;
export class UploadImageModal extends React.Component<Props> {
  // make it general to just get the assetIds
  modalImageComplete = (assetIds: any) => {
    const { modalId, hideModal } = this.props;
    if (!is.array(assetIds)) {
      hideModal(modalId, { assetIds: [assetIds] });
    } else {
      hideModal(modalId, { assetIds });
    }
  };

  onClose = () => this.props.hideModal(this.props.modalId);

  render() {
    const { isReadOnly, activeEditionId, activeSnapId, options } = this.props;
    const purpose = _.get(options, 'purpose');
    return (
      <SDSCustomModal
        visible
        onClose={this.onClose}
        footer={null}
        title={
          <FormattedMessage
            id="upload-image-modal-title"
            description="Upload images Title from modal"
            defaultMessage="Upload"
          />
        }
      >
        <div className={style.rootContainer}>
          <MediaUploader
            isReadOnly={isReadOnly}
            editionId={activeEditionId}
            snapId={activeSnapId}
            purpose={purpose}
            dropzoneType={DropzoneType.MODAL_IMAGE}
            uploadFormat={UploadFormat.MULTIPLE_FILES}
            onMultiFileUploadComplete={({ assetIds }: any) => this.modalImageComplete(assetIds)} // eslint-disable-line
          />
          <div className={style.separator}>
            <FormattedMessage
              id="upload-image-modal-or"
              description="upload image by drag or url"
              defaultMessage="OR"
            />
          </div>
          <MediaUploader
            isReadOnly={isReadOnly}
            editionId={activeEditionId}
            snapId={activeSnapId}
            dropzoneType={DropzoneType.MODAL_IMAGE}
            purpose={purpose}
            uploadFormat={UploadFormat.URL}
            onUploadComplete={({ assetId }: any) => this.modalImageComplete(assetId)} // eslint-disable-line
          />
        </div>
      </SDSCustomModal>
    );
  }
}

export default intlConnect(mapStateToProps, null)(UploadImageModal);
