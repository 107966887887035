import React, { memo, useCallback } from 'react';

import DownloadButton from 'views/common/components/DownloadButton/DownloadButton';

type Props = {
  onDownload: (a: string) => void;
  languageCode: string;
  isDownloading: boolean;
};

function DownloadSubtitlesButton({ onDownload, languageCode, isDownloading }: Props) {
  const handleOnDownload = useCallback(() => {
    onDownload(languageCode);
  }, [onDownload, languageCode]);

  return (
    <DownloadButton
      purpose={`download-subtitles-${languageCode}`}
      onDownload={handleOnDownload}
      isDownloading={isDownloading}
      metricButtonType={'subtitles'}
      extraMetricDimensions={{ 'subtitles.languageCode': languageCode }}
    />
  );
}

export default memo(DownloadSubtitlesButton);
