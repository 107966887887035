import React from 'react';
import { FormattedMessage } from 'react-intl';

import { gapiFailedToLoad, isGoogleAuthLoading } from 'state/auth/selectors/authSelectors';
import { getQuery } from 'state/router/selectors/routerSelectors';
import type { Query } from 'state/router/selectors/routerSelectors';

import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { incrementCounter } from 'utils/grapheneUtils';
import { signInIfUserExists } from 'utils/router/requireLogin';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import Spinner, { SpinnerLabels } from 'views/common/components/Spinner/Spinner';

import { ExtractDispatchProps } from 'types/redux';

export const signInWithGoogle = (
  <FormattedMessage
    id="sign-in-google"
    description="Message on the login button"
    defaultMessage="Sign in with Google"
  />
);

type StateProps = {
  gapiFailedToLoad: boolean;
  isLoading: boolean;
  query: Query;
};

export const mapStateToProps = (state: State) => {
  return {
    isLoading: isGoogleAuthLoading(state),
    query: getQuery(state),
    gapiFailedToLoad: gapiFailedToLoad(state),
  };
};

const mapDispatchToProps = {
  signInIfUserExists,
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = DispatchProps & StateProps;

export class GoogleSignInButton extends React.Component<Props> {
  loginAndRedirect = () => {
    incrementCounter('Login.GoogleAuth', { action: 'loginButton.click' });
    this.props.signInIfUserExists(this.props.query.redirect);
  };

  renderUserMessaging = () => {
    if (this.props.isLoading) {
      return <Spinner loading message={SpinnerLabels.LOADING} />;
    }

    return null;
  };

  render() {
    return (
      <>
        <SDSButton
          type={ButtonType.PRIMARY}
          onClick={this.loginAndRedirect}
          disabled={this.props.isLoading || this.props.gapiFailedToLoad}
        >
          {signInWithGoogle}
        </SDSButton>
        {this.renderUserMessaging()}
      </>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(GoogleSignInButton);
