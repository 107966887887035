import _ from 'lodash';
import React from 'react';
import { intlShape } from 'react-intl';

import { onEnterKey } from 'utils/browserUtils';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { ModalType } from 'utils/modalConfig';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSCustomModal from 'views/common/components/SDSCustomModal/SDSCustomModal';
import SDSInput from 'views/common/components/SDSInput/SDSInput';

import style from './StoryNameModal.scss';

const MessagesMap = new Map([
  [
    ModalType.RENAME_STORY,
    {
      buttonText: getMessageFromId('save-button-text'),
      titleText: getMessageFromId('title-edit-story-name'),
    },
  ],
  [
    ModalType.CREATE_STORY,
    {
      buttonText: getMessageFromId('submit-button-text'),
      titleText: getMessageFromId('title-create-new-story'),
    },
  ],
]);

type Options = {
  modalType: ModalType;
  existingTitle: string;
  onSave: (d: string, b?: File | null) => void;
  'data-test': string;
};

const getMessagesForOptions = _.memoize((options: Options) => {
  const message = MessagesMap.get(options.modalType);
  if (!message) {
    return {
      buttonText: '',
      titleText: '',
    };
  }
  return message;
});

type OwnProps = {
  modalId: string;
  hideModal: (a: string) => void;
  setModalConfigProperties: (a: any) => void;
  modalConfig: any;
  options: Options;
};

type OwnState = {
  value: string;
};

type Props = OwnProps;

export class StoryNameModal extends React.Component<Props, OwnState> {
  static contextTypes = {
    intl: intlShape,
  };

  state: OwnState = {
    value: '',
  };

  UNSAFE_componentWillMount() {
    this.setState({ value: _.get(this, 'props.options.existingTitle', '') });
  }

  onChange = (event: any) => {
    const { value } = event.target;
    this.setState({ value });
  };

  onEnterSaveTitle = onEnterKey((event: any) => {
    event.preventDefault();
    this.saveTitle();
  });

  hideModal = () => {
    this.props.hideModal(this.props.modalId);
  };

  saveTitle = () => {
    const title = this.state.value;
    this.props.options.onSave(title, undefined);
    this.hideModal();
  };

  isCreatingStory = () => this.props.options.modalType === ModalType.CREATE_STORY;

  render() {
    const { buttonText, titleText } = getMessagesForOptions(this.props.options);

    const placeholder = this.context.intl.formatMessage(getMessageFromId('give-your-story-a-name', {}).props);

    return (
      <SDSCustomModal
        visible
        onClose={this.hideModal}
        data-test={this.props.options['data-test']}
        title={titleText}
        footer={
          <SDSButton type={ButtonType.PRIMARY} onClick={this.saveTitle} data-test="modals.storyName.confirm.button">
            {buttonText}
          </SDSButton>
        }
        isBodyCentered
      >
        <div data-test="stories.modal.name.body">
          <SDSInput
            key="textInput"
            maxLength={50}
            labelTitle={getMessageFromId('story-name-field', {})}
            value={this.state.value}
            placeholder={placeholder}
            onChange={this.onChange}
            data-test="StoryNameModal.storyNameFormControl"
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            onKeyDown={this.onEnterSaveTitle}
          />
          {this.isCreatingStory()}
          <div className={style.fieldSubtitle}>{getMessageFromId('story-name-field-hint', {})}</div>
        </div>
      </SDSCustomModal>
    );
  }
}

export default intlConnect(null, null)(StoryNameModal);
