import React from 'react';

import style from './DebugSearch.scss';

const searchMap = {
  boxes: {
    searchTerms: [
      'container',
      'section',
      'info',
      'info box',
      'alert box',
      'box',
      'alert',
      'warning',
      'border',
      'wrapper',
      'div',
    ],
    url: '/debug/boxes/sds',
  },
  buttons: {
    searchTerms: ['link', 'clickable', 'button', 'buttons'],
    url: '/debug/buttons/sds',
  },
  tooltips: {
    searchTerms: ['tooltip', 'screentip'],
    url: '/debug/tooltips/sds',
  },
  icons: {
    searchTerms: ['icon', 'icons', 'svg'],
    url: '/debug/icons/sds',
  },
};
type State = any;
export class DebugSearch extends React.Component<{}, State> {
  state = {
    results: [],
  };

  handleInputChange = (e: any) => {
    e.preventDefault();
    const str = e.target.value.trim();
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const results = Object.keys(searchMap).filter(key => searchMap[key].searchTerms.includes(str));
    this.setState({ results });
  };

  renderResultLinks = () => {
    return this.state.results.map(key => {
      return (
        <div className={style.resultBox}>
          Go to:
          <a key={key} href={(searchMap[key] as any).url}>
            {key}
          </a>
        </div>
      );
    });
  };

  render() {
    return (
      <div className={style.wrapper}>
        <img alt="fairy" src="https://cdn.pixabay.com/photo/2020/09/20/09/54/halloween-5586567_1280.png" />
        <h1>cms search</h1>
        <input
          type="text"
          id="searchbox"
          name="searchbox"
          value={(this.state as any).searchTerm}
          onChange={this.handleInputChange}
          placeholder="What are you looking for? eg. button, tooltip.."
        />
        <div className={style.resultBoxContainer}>{this.state.results.length ? this.renderResultLinks() : null}</div>
      </div>
    );
  }
}
export default DebugSearch;
