import React from 'react';

import { getQuery } from 'state/router/selectors/routerSelectors';
import type { Query } from 'state/router/selectors/routerSelectors';

import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';

import LoginErrorView from 'views/auth/LoginErrorView/LoginErrorView';
import SnapSignInButton from 'views/auth/SnapSignInButton/SnapSignInButton';
import GhostNavBarContainer from 'views/common/GhostNavBarContainer/GhostNavBarContainer';

import style from './LoginWithSnapAuth.scss';

type StateProps = {
  query: Query;
};

type OwnState = {
  loaded: boolean;
};

type Props = StateProps;

const mapStateToProps = (state: State) => ({
  query: getQuery(state),
});

export class LoginWithSnapAuth extends React.Component<Props, OwnState> {
  mounted: boolean;

  constructor(props: Props) {
    super(props);

    this.state = {
      loaded: false,
    };

    this.mounted = true;
  }

  UNSAFE_componentWillMount() {
    // The css might not be loaded yet so we must wait or the spinner will look awful for an instant.
    if (document.readyState === 'complete') {
      this.setState({ loaded: true });
    } else {
      global.window.addEventListener('load', this.updateLoadedState, { capture: true, once: true });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  updateLoadedState = () => {
    if (this.mounted) {
      this.setState({ loaded: true });
    }
  };

  render() {
    if (!this.state.loaded) {
      return null;
    }
    return (
      <GhostNavBarContainer>
        <div className={style.rooting}>
          <div className={style.centered}>
            <div className={style.content}>
              <SnapSignInButton />
              <LoginErrorView error={this.props.query.error} />
            </div>
          </div>
        </div>
      </GhostNavBarContainer>
    );
  }
}

export default intlConnect(mapStateToProps, null)(LoginWithSnapAuth);
