import React from 'react';
import { FormattedMessage } from 'react-intl';

import { login } from 'state/auth/actions/snapAuthActions';

import style from 'styles/yellowFilledButton.scss';
import { intlConnect } from 'utils/connectUtils';
import { incrementCounter } from 'utils/grapheneUtils';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';

import { ExtractDispatchProps } from 'types/redux';

export const signInWithSnapchat = (
  <FormattedMessage
    id="sign-in-snapchat"
    description="Message on the login button"
    defaultMessage="Sign in with Snapchat"
  />
);

const mapDispatchToProps = {
  login,
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = DispatchProps;

export class SnapSignInButton extends React.Component<Props> {
  onClick = () => {
    incrementCounter('Login.SnapAuth', { action: 'loginButton.click' });
    this.props.login();
  };

  render() {
    return (
      <SDSButton
        type={ButtonType.RED_ON_WHITE}
        onClick={this.onClick}
        data-test="auth.snap.signIn.button"
        className={style.yellowFilledButton}
      >
        {signInWithSnapchat}
      </SDSButton>
    );
  }
}

export default intlConnect(undefined, mapDispatchToProps)(SnapSignInButton);
