import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';

import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { printTwoDecimal } from 'utils/mathUtils';
import {
  prettyPrintNumber,
  privacyNumberFormat,
  printPercentage,
  printSeconds,
  printMultiplier,
  printDollars,
  printMicrocentsAsDollars,
  convertMsToSingleTimeUnit,
  formatTimeViewed,
} from 'utils/numberFormatter';

import { GRAPH_SHADES_OF_BLUE } from 'views/analytics/utils/chartConfigs';

import { ProfileMetric, SpotlightMetric, SpotlightSummary, StoriesMetric } from 'types/analytics';

function defaultFindValue(this: any, allKPI: any) {
  if (!allKPI) {
    return null;
  }
  const { metricId } = this;
  if (metricId in allKPI) {
    const value = parseFloat(allKPI[metricId]);
    if (!Number.isNaN(Number(value))) {
      return value;
    }
  }
  return null;
}
function attachFindValue(kpiConfig: any) {
  if ('findValue' in kpiConfig) {
    return;
  }
  const nextKpiConfig = kpiConfig;
  nextKpiConfig.findValue = defaultFindValue;
}

export const KPI_NO_METRIC_INFO_MESSAGE = (
  <FormattedMessage
    id="kpi-no-metric-info-message"
    description="Metrics are only displayed when a story gets >1k views.
    Message shown when there's no metrics to display in analytics"
    defaultMessage="Below threshold"
  />
);

const storyTotalMsg = (
  <FormattedMessage
    id="unique-viewers-subtitle-publisher-story-total"
    description="Publisher Story Total"
    defaultMessage="Story Total"
  />
);

const UNIQUE_DAU_METRIC = {
  metricId: 'uniqueDau',
  name: (
    <FormattedMessage
      id="publisher-story-kpi-name-unique-viewers"
      description="Unique Viewers"
      defaultMessage="Unique Viewers"
    />
  ),
  tooltip: (
    <FormattedMessage
      id="publisher-story-kpi-unique-viewer-explanation"
      description="Unique Viewers Explanation in Publisher Story KPI"
      defaultMessage="Total number of Unique Snapchatters that engaged with this story."
    />
  ),
  formatFunc: privacyNumberFormat,
  previousValueDescription: weekOverWeek,
  previousValueDescriptionLong: weekOverWeekLong,
  subtitle: storyTotalMsg,
};

const SUBSCRIBER_COUNT_METRIC = {
  metricId: 'publisherSubscribeCount',
  name: (
    <FormattedMessage
      id="publisher-story-kpi-name-followers-added"
      description="Followers Added"
      defaultMessage="Followers Added"
    />
  ),
  tooltip: (
    <FormattedMessage
      id="publisher-story-kpi-followers-added-explanation"
      description="Followers Added Explanation in Publisher Story KPI"
      defaultMessage="Total number of new followers of your profile when this Story was live."
    />
  ),
  formatFunc: privacyNumberFormat,
  previousValueDescription: weekOverWeek,
  previousValueDescriptionLong: weekOverWeekLong,
  subtitle: storyTotalMsg,
};

export const DAILY_KPI_V3_CONFIG = [
  {
    metricId: 'dau',
    name: (
      <FormattedMessage id="daily-kpi-name-daily-uniques" description="Daily Uniques" defaultMessage="Daily Uniques" />
    ),
    tooltip: (
      <FormattedMessage
        id="daily-kpi-daily-uniques-explanation"
        description="Daily Uniques Explanation in Daily KPI"
        defaultMessage="Number of Unique Snapchatters that engaged with your channel per 24 hours."
      />
    ),
    formatFunc: privacyNumberFormat,
    previousValueDescription: rangeOverRange,
    previousValueDescriptionLong: rangeOverRangeLong,
    subtitle: avgSubtitle,
  },
  {
    metricId: 'mau',
    name: (
      <FormattedMessage
        id="daily-kpi-name-total-unique-viewers"
        description="Total Unique Viewers"
        defaultMessage="Total Unique Viewers"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="daily-kpi-total-uniques-viewers-explanation"
        description="Total Unique Viewers Explanation in Daily KPI"
        defaultMessage="Number of Unique Snapchatters that engaged with your channel over the selected time period."
      />
    ),
    formatFunc: privacyNumberFormat,
    previousValueDescription: rangeOverRange,
    previousValueDescriptionLong: rangeOverRangeLong,
    subtitle: totalSubtitle,
  },
  {
    metricId: 'coreUser',
    name: (
      <FormattedMessage
        id="daily-kpi-name-daily-core-users"
        description="Daily Core Users"
        defaultMessage="Daily Core Users"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="daily-kpi-daily-core-users-explanation"
        description="Daily Core Users Explanation in Daily KPI"
        defaultMessage="Number of Unique Snapchatters that engaged with your channel at least 3 out of the last 7 days."
      />
    ),
    formatFunc: privacyNumberFormat,
    previousValueDescription: rangeOverRange,
    previousValueDescriptionLong: rangeOverRangeLong,
    subtitle: avgSubtitle,
  },
  {
    metricId: 'timeViewedSecs',
    name: <FormattedMessage id="daily-kpi-name-time-viewed" description="Time Viewed" defaultMessage="Time Viewed" />,
    tooltip: (
      <FormattedMessage
        id="daily-kpi-time-viewed-explanation"
        description="Time Viewed Explanation in Daily KPI"
        defaultMessage="Amount of time on average that Unique Snap Viewers spent viewing your channel."
      />
    ),
    formatFunc: printSeconds,
    previousValueDescription: rangeOverRange,
    previousValueDescriptionLong: rangeOverRangeLong,
    subtitle: avgSubtitle,
  },
  {
    metricId: 'longformConversionRate',
    name: (
      <FormattedMessage
        id="daily-kpi-name-attachment-conversation"
        description="Attachment Conversion"
        defaultMessage="Attachment Conversion"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="daily-kpi-attachment-conversation-explanation"
        description="Attachment Conversation Explanation in Daily KPI"
        defaultMessage="Percentage of Unique Snap Viewers that swiped up on snaps with attachments."
      />
    ),
    formatFunc: printPercentage,
    previousValueDescription: rangeOverRange,
    previousValueDescriptionLong: rangeOverRangeLong,
    percentChanged: percentChangedForPercentValues,
    subtitle: avgSubtitle,
  },
  {
    metricId: 'dauSubscribers',
    name: (
      <FormattedMessage
        id="daily-kpi-name-daily-follower-uniques"
        description="Daily Follower Uniques"
        defaultMessage="Daily unique viewers (followers)"
      />
    ),
    tooltip: (
      <>
        <FormattedMessage
          id="daily-kpi-daily-follower-uniques-explanation"
          description="Daily Follower Uniques Explanation in Daily KPI"
          defaultMessage="Number of unique followers who engaged with your profile over 24 hours."
        />
        &nbsp;
        {getMessageFromId('subscription-analytics-excludes-spotlight-subscription-notice')}
      </>
    ),
    formatFunc: privacyNumberFormat,
    previousValueDescription: rangeOverRange,
    previousValueDescriptionLong: rangeOverRangeLong,
    subtitle: totalSubtitle,
  },
  {
    metricId: 'mauSubscribers',
    name: (
      <FormattedMessage
        id="daily-kpi-name-total-unique-followers-viewers"
        description="Total unique viewers who are followers."
        defaultMessage="Total unique viewers who are followers."
      />
    ),
    tooltip: (
      <>
        <FormattedMessage
          id="daily-kpi-total-unique-follower-viewers-explanation"
          description="Total Unique Follower Viewers Explanation in Daily KPI"
          defaultMessage="Number of unique followers who engaged with your profile over the selected time period"
        />
        &nbsp;
        {getMessageFromId('subscription-analytics-excludes-spotlight-subscription-notice')}
      </>
    ),
    formatFunc: privacyNumberFormat,
    previousValueDescription: rangeOverRange,
    previousValueDescriptionLong: rangeOverRangeLong,
    subtitle: totalSubtitle,
  },
  {
    metricId: 'storiesPublished',
    name: (
      <FormattedMessage
        id="daily-kpi-name-stories-published"
        description="Snaps Published"
        defaultMessage="Snaps Published"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="daily-kpi-stories-published-explanation"
        description="Snaps Published Explanation in Daily KPI"
        defaultMessage="Number of Snaps Published"
      />
    ),
    formatFunc: prettyPrintNumber,
    previousValueDescription: rangeOverRange,
    previousValueDescriptionLong: rangeOverRangeLong,
    subtitle: totalSubtitle,
  },
  {
    metricId: 'completionRate',
    name: (
      <FormattedMessage
        id="daily-kpi-name-completion-rate"
        description="Completion Rate"
        defaultMessage="Completion Rate"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="daily-kpi-completion-rate-explanation"
        description="Completion Rate Explanation in Daily KPI"
        defaultMessage="Percentage of Unique Snap Viewers that saw the last snap."
      />
    ),
    formatFunc: printPercentage,
    previousValueDescription: rangeOverRange,
    previousValueDescriptionLong: rangeOverRangeLong,
    percentChanged: percentChangedForPercentValues,
    subtitle: avgSubtitle,
  },
  {
    metricId: 'uniqueTopsnapViewPerUser',
    name: (
      <FormattedMessage
        id="daily-kpi-name-avg-unique-topsnap-view"
        description="Unique Topsnaps Per User"
        defaultMessage="Unique Topsnaps Per User"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="daily-kpi-name-avg-unique-topsnap-view-explanation"
        description="Unique Topsnaps Per User Explanation in Daily KPI"
        defaultMessage="The average number of topsnaps in your story viewed by Snapchatters"
      />
    ),
    formatFunc: printTwoDecimal,
    previousValueDescription: rangeOverRange,
    previousValueDescriptionLong: rangeOverRangeLong,
    subtitle: avgSubtitle,
  },
  {
    metricId: 'uniqueTopsnapViewCount',
    name: (
      <FormattedMessage
        id="daily-kpi-name-unique-topsnap-views"
        description="Unique Topsnap Views"
        defaultMessage="Unique Topsnap Views"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="daily-kpi-name-unique-topsnap-view-count-explanation"
        description="Unique Topsnap View Count Explanation in Daily KPI"
        defaultMessage="The total number of unique topsnaps viewed by all Snapchatters that engaged with your content"
      />
    ),
    formatFunc: privacyNumberFormat,
    previousValueDescription: rangeOverRange,
    previousValueDescriptionLong: rangeOverRangeLong,
    subtitle: totalSubtitle,
  },
  {
    metricId: 'totalTimeViewed',
    name: (
      <FormattedMessage
        id="daily-kpi-name-total-time-viewed"
        description="Total Time Viewed"
        defaultMessage="Total Time Viewed"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="daily-kpi-name-total-time-viewed-explanation"
        description="Total Time Viewed Explanation in Daily KPI"
        defaultMessage="The total amount of time users viewed your content (topsnaps and attachments)"
      />
    ),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    formatFunc: (v: any) => `${privacyNumberFormat(parseInt(v / 60, 10))} min`,
    previousValueDescription: rangeOverRange,
    previousValueDescriptionLong: rangeOverRangeLong,
    subtitle: totalSubtitle,
  },
];

export const EDITION_KPI_CONFIG = [
  UNIQUE_DAU_METRIC,
  SUBSCRIBER_COUNT_METRIC,
  {
    metricId: 'derivedTotalViews',
    findValue: (allKPI: any) => {
      if (!allKPI) {
        return null;
      }
      const { topSnapViewCount, longformViewCount } = allKPI;
      const topNum = parseFloat(topSnapViewCount);
      const longformNum = parseFloat(longformViewCount);
      if (Number.isNaN(Number(topNum)) && Number.isNaN(Number(longformNum))) {
        return null;
      }
      return (Number.isNaN(Number(topNum)) ? 0 : topNum) + (Number.isNaN(Number(longformNum)) ? 0 : longformNum);
    },
    name: (
      <FormattedMessage
        id="publisher-story-kpi-name-total-views"
        description="Total Views"
        defaultMessage="Total Views"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="publisher-story-kpi-total-views-explanation"
        description="Total Views Explanation in Publisher Story KPI"
        defaultMessage="Total views of topsnaps and attachments from this story."
      />
    ),
    formatFunc: privacyNumberFormat,
    previousValueDescription: weekOverWeek,
    previousValueDescriptionLong: weekOverWeekLong,
    subtitle: (
      <FormattedMessage
        id="total-views-subtitle-publisher-story-total"
        description="Publisher Story Total"
        defaultMessage="Story Total"
      />
    ),
  },
  {
    metricId: 'completionRate',
    findValue: (allKPI: any, { isSubscribable, snaps }: any) => {
      if (!allKPI || !Object.keys(allKPI).length) {
        return null;
      }

      if (!snaps || !snaps.length) {
        return null;
      }

      const lastSnapIndex = isSubscribable ? snaps.length - 2 : snaps.length - 1;
      const snapViewers = snaps[lastSnapIndex];
      return snapViewers.uniqueDau / allKPI.uniqueDau;
    },
    name: (
      <FormattedMessage
        id="publisher-story-kpi-name-completion-rate"
        description="Completion Rate"
        defaultMessage="Completion Rate"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="publisher-story-kpi-completion-rate-explanation"
        description="Completion Rate Explanation in Publisher Story KPI"
        defaultMessage="Percentage of Unique Snapchatters that saw the last snap."
      />
    ),
    formatFunc: printPercentage,
    percentChanged: percentChangedForPercentValues,
    previousValueDescription: weekOverWeek,
    previousValueDescriptionLong: weekOverWeekLong,
    subtitle: (
      <FormattedMessage
        id="analytics-kpi-story-average"
        description="Publisher Story Average"
        defaultMessage="Story Average"
      />
    ),
  },
  {
    metricId: 'uniqueTopSnapViewPerUser',
    name: (
      <FormattedMessage
        id="publisher-story-kpi-name-avg-unique-topsnap-view"
        description="Unique Topsnaps Per User"
        defaultMessage="Unique Topsnaps Per User"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="publisher-story-kpi-name-avg-unique-topsnap-view-explanation"
        description="Unique Topsnaps Per User Explanation in Publisher Story KPI"
        defaultMessage="The average number of topsnaps in your story viewed by Snapchatters"
      />
    ),
    formatFunc: printTwoDecimal,
    previousValueDescription: weekOverWeek,
    previousValueDescriptionLong: weekOverWeekLong,
    subtitle: (
      <FormattedMessage
        id="analytics-kpi-story-average"
        description="Publisher Story Average"
        defaultMessage="Story Average"
      />
    ),
  },
  {
    metricId: 'avgTotalTimeViewed',
    name: (
      <FormattedMessage
        id="publisher-story-kpi-name-time-viewed"
        description="Time Viewed"
        defaultMessage="Time Viewed"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="publisher-story-kpi-time-viewed-explanation"
        description="Time Viewed Explanation in Publisher Story KPI"
        defaultMessage="Amount of time on average that Unique Snapchatters spent viewing this story."
      />
    ),
    formatFunc: formatTimeViewed,
    previousValueDescription: weekOverWeek,
    previousValueDescriptionLong: weekOverWeekLong,
    subtitle: (
      <FormattedMessage
        id="analytics-kpi-story-average"
        description="Publisher Story Average"
        defaultMessage="Story Average"
      />
    ),
  },
  {
    metricId: 'longformConversionRate',
    name: (
      <FormattedMessage
        id="publisher-story-kpi-name-attachment-conversation"
        description="Attachment Conversion"
        defaultMessage="Attachment Conversion"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="publisher-story-kpi-attachment-conversation-explanation"
        description="Attachment Conversion Explanation in Publisher Story KPI"
        defaultMessage="Percentage of Unique Snapchatters that swiped up on snaps with attachments."
      />
    ),
    formatFunc: printPercentage,
    percentChanged: percentChangedForPercentValues,
    previousValueDescription: weekOverWeek,
    previousValueDescriptionLong: weekOverWeekLong,
    subtitle: (
      <FormattedMessage
        id="analytics-kpi-story-average"
        description="Publisher Story Average"
        defaultMessage="Story Average"
      />
    ),
  },
  {
    metricId: 'totalShareCount',
    name: <FormattedMessage id="publisher-story-kpi-name-shares" description="Shares" defaultMessage="Shares" />,
    tooltip: (
      <FormattedMessage
        id="publisher-story-kpi-shares-explanation"
        description="Shares Explanation in Publisher Story KPI"
        defaultMessage="Total number of shares of snaps from this story."
      />
    ),
    formatFunc: privacyNumberFormat,
    previousValueDescription: weekOverWeek,
    previousValueDescriptionLong: weekOverWeekLong,
    subtitle: (
      <FormattedMessage
        id="shares-subtitle-publisher-story-total"
        description="Publisher Story Total"
        defaultMessage="Story Total"
      />
    ),
  },
  {
    metricId: 'totalScreenshotCount',
    name: (
      <FormattedMessage
        id="publisher-story-kpi-name-screenshots"
        description="Screenshots"
        defaultMessage="Screenshots"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="publisher-story-kpi-screenshots-explanation"
        description="Screenshots Explanation in Publisher Story KPI"
        defaultMessage="Total number of screenshots of snaps from this story."
      />
    ),
    formatFunc: privacyNumberFormat,
    previousValueDescription: weekOverWeek,
    previousValueDescriptionLong: weekOverWeekLong,
    subtitle: (
      <FormattedMessage
        id="screenshots-subtitle-publisher-story-total"
        description="Publisher Story Total"
        defaultMessage="Story Total"
      />
    ),
  },
  {
    metricId: 'avgViewedPercentage',
    name: (
      <FormattedMessage
        id="publisher-story-kpi-name-average-viewed-percentage"
        description="Story Percentage Viewed"
        defaultMessage="Story Percentage Viewed"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="publisher-story-kpi-average-viewed-percentage-explanation"
        description="Average Viewed Ratio Explanation in Publisher Story KPI"
        defaultMessage="Average percentage of the snap story that Unique Snapchatters saw."
      />
    ),
    findValue: (allKPI: any, args: any) => {
      const { storyRuntime } = args || {};
      if (!allKPI || !storyRuntime) {
        return null;
      }
      const metricId = 'avgTotalTimeViewed';
      if (metricId in allKPI) {
        const value = parseFloat(allKPI[metricId]);
        if (!Number.isNaN(Number(value))) {
          return value / storyRuntime;
        }
      }
      return null;
    },
    displayRatio: true,
    formatFunc: printPercentage,
    previousValueDescription: weekOverWeek,
    previousValueDescriptionLong: weekOverWeekLong,
    subtitle: (
      <FormattedMessage
        id="analytics-kpi-story-average-viewed-percentage"
        description="Publisher Story Average Viewed Percentage"
        defaultMessage="Story Average"
      />
    ),
  },
];

export const EDITION_KPI_CONFIG_V2 = [
  UNIQUE_DAU_METRIC,
  SUBSCRIBER_COUNT_METRIC,
  {
    metricId: 'derivedTotalViews',
    findValue: (allKPI: any) => {
      if (!allKPI) {
        return null;
      }
      const { topSnapViewCount } = allKPI;
      const topNum = parseFloat(topSnapViewCount);
      if (Number.isNaN(Number(topNum))) {
        return null;
      }
      return Number.isNaN(Number(topNum)) ? 0 : topNum;
    },
    name: (
      <FormattedMessage
        id="publisher-story-kpi-name-total-views"
        description="Total Views"
        defaultMessage="Total Views"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="publisher-story-kpi-total-views-explanation"
        description="Total Views Explanation in Publisher Story KPI"
        defaultMessage="Total views of topsnaps and attachments from this story."
      />
    ),
    formatFunc: privacyNumberFormat,
    previousValueDescription: weekOverWeek,
    previousValueDescriptionLong: weekOverWeekLong,
    subtitle: (
      <FormattedMessage
        id="total-views-subtitle-publisher-story-total"
        description="Publisher Story Total"
        defaultMessage="Story Total"
      />
    ),
  },
  {
    metricId: 'completionRate',
    findValue: (allKPI: any, { isSubscribable, snaps }: any) => {
      if (!allKPI || !Object.keys(allKPI).length) {
        return null;
      }

      if (!snaps || !snaps.length) {
        return null;
      }

      const lastSnapIndex = isSubscribable ? snaps.length - 2 : snaps.length - 1;
      const snapViewers = snaps[lastSnapIndex];
      return snapViewers.uniqueDau / allKPI.uniqueDau;
    },
    name: (
      <FormattedMessage
        id="publisher-story-kpi-name-completion-rate"
        description="Completion Rate"
        defaultMessage="Completion Rate"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="publisher-story-kpi-completion-rate-explanation"
        description="Completion Rate Explanation in Publisher Story KPI"
        defaultMessage="Percentage of Unique Snapchatters that saw the last snap."
      />
    ),
    formatFunc: printPercentage,
    percentChanged: percentChangedForPercentValues,
    previousValueDescription: weekOverWeek,
    previousValueDescriptionLong: weekOverWeekLong,
    subtitle: (
      <FormattedMessage
        id="analytics-kpi-story-average"
        description="Publisher Story Average"
        defaultMessage="Story Average"
      />
    ),
  },
  {
    metricId: 'uniqueTopSnapViewPerUser',
    name: (
      <FormattedMessage
        id="publisher-story-kpi-name-avg-unique-topsnap-view"
        description="Unique Topsnaps Per User"
        defaultMessage="Unique Topsnaps Per User"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="publisher-story-kpi-name-avg-unique-topsnap-view-explanation"
        description="Unique Topsnaps Per User Explanation in Publisher Story KPI"
        defaultMessage="The average number of topsnaps in your story viewed by Snapchatters"
      />
    ),
    formatFunc: printTwoDecimal,
    previousValueDescription: weekOverWeek,
    previousValueDescriptionLong: weekOverWeekLong,
    subtitle: (
      <FormattedMessage
        id="analytics-kpi-story-average"
        description="Publisher Story Average"
        defaultMessage="Story Average"
      />
    ),
  },
  {
    metricId: 'avgTotalTimeViewed',
    name: (
      <FormattedMessage
        id="publisher-story-kpi-name-time-viewed"
        description="Time Viewed"
        defaultMessage="Time Viewed"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="publisher-story-kpi-time-viewed-explanation"
        description="Time Viewed Explanation in Publisher Story KPI"
        defaultMessage="Amount of time on average that Unique Snapchatters spent viewing this story."
      />
    ),
    formatFunc: formatTimeViewed,
    previousValueDescription: weekOverWeek,
    previousValueDescriptionLong: weekOverWeekLong,
    subtitle: (
      <FormattedMessage
        id="analytics-kpi-story-average"
        description="Publisher Story Average"
        defaultMessage="Story Average"
      />
    ),
  },
  {
    metricId: 'longformConversionRate',
    name: (
      <FormattedMessage
        id="publisher-story-kpi-name-attachment-conversation"
        description="Attachment Conversion"
        defaultMessage="Attachment Conversion"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="publisher-story-kpi-attachment-conversation-explanation"
        description="Attachment Conversion Explanation in Publisher Story KPI"
        defaultMessage="Percentage of Unique Snapchatters that swiped up on snaps with attachments."
      />
    ),
    formatFunc: printPercentage,
    percentChanged: percentChangedForPercentValues,
    previousValueDescription: weekOverWeek,
    previousValueDescriptionLong: weekOverWeekLong,
    subtitle: (
      <FormattedMessage
        id="analytics-kpi-story-average"
        description="Publisher Story Average"
        defaultMessage="Story Average"
      />
    ),
  },
  {
    metricId: 'totalShareCount',
    name: <FormattedMessage id="publisher-story-kpi-name-shares" description="Shares" defaultMessage="Shares" />,
    tooltip: (
      <FormattedMessage
        id="publisher-story-kpi-shares-explanation"
        description="Shares Explanation in Publisher Story KPI"
        defaultMessage="Total number of shares of snaps from this story."
      />
    ),
    formatFunc: privacyNumberFormat,
    previousValueDescription: weekOverWeek,
    previousValueDescriptionLong: weekOverWeekLong,
    subtitle: (
      <FormattedMessage
        id="shares-subtitle-publisher-story-total"
        description="Publisher Story Total"
        defaultMessage="Story Total"
      />
    ),
  },
  {
    metricId: 'totalScreenshotCount',
    name: (
      <FormattedMessage
        id="publisher-story-kpi-name-screenshots"
        description="Screenshots"
        defaultMessage="Screenshots"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="publisher-story-kpi-screenshots-explanation"
        description="Screenshots Explanation in Publisher Story KPI"
        defaultMessage="Total number of screenshots of snaps from this story."
      />
    ),
    formatFunc: privacyNumberFormat,
    previousValueDescription: weekOverWeek,
    previousValueDescriptionLong: weekOverWeekLong,
    subtitle: (
      <FormattedMessage
        id="screenshots-subtitle-publisher-story-total"
        description="Publisher Story Total"
        defaultMessage="Story Total"
      />
    ),
  },
  {
    metricId: 'avgViewedPercentage',
    name: (
      <FormattedMessage
        id="publisher-story-kpi-name-average-viewed-percentage"
        description="Story Percentage Viewed"
        defaultMessage="Story Percentage Viewed"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="publisher-story-kpi-average-viewed-percentage-explanation"
        description="Average Viewed Ratio Explanation in Publisher Story KPI"
        defaultMessage="Average percentage of the snap story that Unique Snapchatters saw."
      />
    ),
    findValue: (allKPI: any, args: any) => {
      const { storyRuntime } = args || {};
      if (!allKPI || !storyRuntime) {
        return null;
      }
      const metricId = 'avgTotalTimeViewed';
      if (metricId in allKPI) {
        const value = parseFloat(allKPI[metricId]);
        if (!Number.isNaN(Number(value))) {
          return value / storyRuntime;
        }
      }
      return null;
    },
    displayRatio: true,
    formatFunc: printPercentage,
    previousValueDescription: weekOverWeek,
    previousValueDescriptionLong: weekOverWeekLong,
    subtitle: (
      <FormattedMessage
        id="analytics-kpi-story-average-viewed-percentage"
        description="Publisher Story Average Viewed Percentage"
        defaultMessage="Story Average"
      />
    ),
  },
];

export const SEGMENT_KPI_CONFIG_IDS = {
  impressionPercentage: 'impressionPercentage',
  indexedCtr: 'indexedCtr',
};

export const SEGMENT_KPI_CONFIG = [
  {
    metricId: 'avgTotalTimeViewed',
    name: <FormattedMessage id="segment-kpi-time-viewed" description="Time Viewed" defaultMessage="Time Viewed" />,
    tooltip: (
      <FormattedMessage
        id="segment-snap-time-viewed-explanation"
        description="Time viewed explanation in segment analytics"
        defaultMessage="The average amount of time Snapchatters spent viewing the segment."
      />
    ),
    formatFunc: printSeconds,
    subtitle: (
      <FormattedMessage
        id="segment-time-viewed-average"
        description="Time Viewed Average"
        defaultMessage="Segment Average"
      />
    ),
  },
  {
    metricId: 'uniqueTopSnapViewPerUser',
    name: <FormattedMessage id="segment-kpi-snaps-viewed" description="Snaps Viewed" defaultMessage="Snaps Viewed" />,
    tooltip: (
      <FormattedMessage
        id="segment-unique-snaps-viewed-explanation"
        description="Snaps viewed explanation in segment analytics"
        defaultMessage="The average number of unique topsnaps Snapchatters viewed in your segment"
      />
    ),
    formatFunc: printTwoDecimal,
    subtitle: (
      <FormattedMessage
        id="segment-snaps-viewed-average"
        description="Snaps Viewed Average"
        defaultMessage="Segment Average"
      />
    ),
  },
];

export const SEGMENT_TILES_KPI_CONFIG = [
  {
    metricId: 'impressionPercentage',
    name: (
      <FormattedMessage
        id="publisher-story-kpi-name-segment-tile-uv"
        description="% UVs that saw segment tile"
        defaultMessage="% UVs that saw segment tile"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="publisher-story-kpi-name-segment-tile-uv-explanation"
        description="% of unique viewers that saw tile KPI"
        defaultMessage="Share of unique viewers that saw the tile"
      />
    ),
    subtitle: storyTotalMsg,
    formatFunc: printPercentage,
  },
  {
    metricId: 'indexedCtr',
    name: (
      <FormattedMessage
        id="publisher-story-kpi-name-indexed-ctr"
        description="Indexed CTR"
        defaultMessage="Indexed CTR"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="publisher-story-kpi-indexed-ctr-explanation"
        description="Indexed CTR"
        defaultMessage={
          'The Click Through Rate (CTR) shows how tappable a segment is by dividing clicks' +
          ' by impressions. CTRs are indexed vs the story average.'
        }
      />
    ),
    subtitle: storyTotalMsg,
    formatFunc: printMultiplier,
  },
];

export const PAY_TO_PROMOTE_KPI_CONFIG_ADS_MANAGER = [
  {
    metricId: 'paidImpressions',
    name: (
      <FormattedMessage
        id="publisher-story-kpi-name-paid-impressions"
        description="Number of paid impressions on the story"
        defaultMessage="Paid Impressions"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="publisher-story-kpi-name-paid-impressions-explanation"
        description="Number of paid impressions on the story"
        defaultMessage="Number of paid Impressions on the story"
      />
    ),
    subtitle: storyTotalMsg,
    formatFunc: privacyNumberFormat,
  },
  {
    metricId: 'paidEcpm',
    name: (
      <FormattedMessage id="publisher-story-kpi-name-paid-ecpm" description="Paid ECPM" defaultMessage="Paid ECPM" />
    ),
    tooltip: (
      <FormattedMessage
        id="publisher-story-kpi-name-paid-ecpm-explanation"
        description="Paid ECPM"
        defaultMessage="Effective Cost Per Mille (CTR) shows the cost of ad space per 1000 impressions"
      />
    ),
    subtitle: storyTotalMsg,
    formatFunc: printMicrocentsAsDollars,
  },
  {
    metricId: 'spend',
    name: <FormattedMessage id="publisher-story-kpi-spend" description="Spend" defaultMessage="Spend" />,
    tooltip: (
      <FormattedMessage
        id="publisher-story-kpi-name-spend-explanation"
        description="Total $ spent on advertising the story"
        defaultMessage="Total $ spent on advertising the story"
      />
    ),
    subtitle: storyTotalMsg,
    formatFunc: printMicrocentsAsDollars,
  },
  {
    metricId: 'swipes',
    name: <FormattedMessage id="publisher-story-kpi-name-swipes" description="Swipes" defaultMessage="Swipes" />,
    tooltip: (
      <FormattedMessage
        id="publisher-story-kpi-name-swipes-explanation"
        description="Swipes"
        defaultMessage="Number of swipes on the story"
      />
    ),
    subtitle: storyTotalMsg,
    formatFunc: privacyNumberFormat,
  },
];

// We have two sets of KPIs for Pay To Promote: one set from Ads Manager, one set from Story Studio.
// We keep them in separate configs because some users will have permissions to view Story Studio KPIs, but not Ads Manager ones.

export const REVENUE_KPI_CONFIG = [
  {
    metricId: 'impressions',
    name: (
      <FormattedMessage
        id="publisher-kpi-name-impressions"
        description="Number of impressions on the profile"
        defaultMessage="Impressions"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="publisher-kpi-name-impressions-explanation"
        description="Number of impressions on the profile"
        defaultMessage="Number of impressions on the profile"
      />
    ),
    formatFunc: privacyNumberFormat,
  },
  {
    metricId: 'revenue',
    name: (
      <FormattedMessage
        id="publisher-kpi-name-revenue"
        description="Revenue earned on profile"
        defaultMessage="Revenue"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="publisher-kpi-name-revenue-explanation"
        description="Revenue"
        defaultMessage="Total Revenue on profile"
      />
    ),
    formatFunc: printDollars,
  },
  {
    metricId: 'ecpm',
    name: <FormattedMessage id="publisher-kpi-ecpm" description="eCPM" defaultMessage="eCPM" />,
    tooltip: (
      <FormattedMessage
        id="publisher-kpi-name-ecpm-explanation"
        description="Effective Cost Per Mille (CTR) shows the cost of ad space per 1000 impressions"
        defaultMessage="Effective Cost Per Mille (CTR) shows the cost of ad space per 1000 impressions"
      />
    ),
    formatFunc: printDollars,
  },
] as const;

export const PAY_TO_PROMOTE_KPI_CONFIG_STORY_STUDIO = [UNIQUE_DAU_METRIC, SUBSCRIBER_COUNT_METRIC];

export const EDITION_KPI_MAX_VALUE_CONFIG = {
  avgTotalTimeViewed: {
    description: (
      <FormattedMessage
        id="analytics-kpi-story-total-video-length"
        description="Publisher Story Total Snap Duration"
        defaultMessage="Video Length"
      />
    ),
    descriptionLong: (
      <FormattedMessage
        id="analytics-kpi-story-total-video-lenght-explanation"
        description="Publisher Story Total Snap Duration Long Description"
        defaultMessage="Duration of all snaps in the story combined. Does not include attachments"
      />
    ),
    formatFunc: printSeconds,
    isVisibleOnlyForShows: true,
  },
  uniqueTopSnapViewPerUser: {
    description: (
      <FormattedMessage
        id="analytics-kpi-story-total-length"
        description="Publisher Story Total Snap Count"
        defaultMessage="Number of Snaps"
      />
    ),
    descriptionLong: (
      <FormattedMessage
        id="analytics-kpi-story-total-lenght-explanation-end-snap"
        description="Publisher Story Total Snap Count Long Description"
        defaultMessage="Number of Snaps in Story includes End Snap (End Snap is not shown to followers)"
      />
    ),
    formatFunc: (x: any) => x,
  },
  uniqueDau: {
    isSuperAdminOnly: true,
    description: (
      <FormattedMessage
        id="analytics-kpi-story-unique-follower-views"
        description="Publisher Story Unique Follower Views"
        defaultMessage="From your Followers"
      />
    ),
    descriptionLong: (
      <FormattedMessage
        id="analytics-kpi-story-unique-follower-views-explanation"
        description="Publisher Story Unique Follower Views Description"
        defaultMessage="Number of unique viewers who were existing followers of your profile."
      />
    ),
    formatFunc: privacyNumberFormat,
  },
};

export const EDITION_KPI_MAX_VALUE_CONFIG_V2 = {
  avgTotalTimeViewed: {
    description: (
      <FormattedMessage
        id="analytics-kpi-story-total-video-length"
        description="Publisher Story Total Snap Duration"
        defaultMessage="Video Length"
      />
    ),
    descriptionLong: (
      <FormattedMessage
        id="analytics-kpi-story-total-video-lenght-explanation"
        description="Publisher Story Total Snap Duration Long Description"
        defaultMessage="Duration of all snaps in the story combined. Does not include attachments"
      />
    ),
    formatFunc: formatTimeViewed,
    isVisibleOnlyForShows: true,
  },
  uniqueTopSnapViewPerUser: {
    description: (
      <FormattedMessage
        id="analytics-kpi-story-total-length"
        description="Publisher Story Total Snap Count"
        defaultMessage="Number of Snaps"
      />
    ),
    descriptionLong: (
      <FormattedMessage
        id="analytics-kpi-story-total-lenght-explanation-end-snap"
        description="Publisher Story Total Snap Count Long Description"
        defaultMessage="Number of Snaps in Story includes End Snap (End Snap is not shown to followers)"
      />
    ),
    formatFunc: (x: any) => x,
  },
  uniqueDau: {
    isSuperAdminOnly: true,
    description: (
      <FormattedMessage
        id="analytics-kpi-story-unique-follower-views"
        description="Publisher Story Unique Follower Views"
        defaultMessage="From your Followers"
      />
    ),
    descriptionLong: (
      <FormattedMessage
        id="analytics-kpi-story-unique-follower-views-explanation"
        description="Publisher Story Unique Follower Views Description"
        defaultMessage="Number of unique viewers who were existing followers of your profile."
      />
    ),
    formatFunc: privacyNumberFormat,
  },
};

export const PAY_TO_PROMOTE_MAX_VALUE_CONFIG = {
  paidUniqueDau: {
    description: (
      <FormattedMessage
        id="analytics-kpi-story-unique-views-paid"
        description="Publisher Story Paid Views"
        defaultMessage="Paid"
      />
    ),
    descriptionLong: (
      <FormattedMessage
        id="analytics-kpi-story-unique-views-paid-explanation"
        description="Publisher Story Unique Views Paid Description"
        defaultMessage="Number of unique viewers who viewed the story through a promotion"
      />
    ),
    formatFunc: privacyNumberFormat,
  },
  paidSubscriberCount: {
    description: (
      <FormattedMessage
        id="analytics-kpi-story-subscriber-count-paid"
        description="Publisher Story Paid Subscribers"
        defaultMessage="Paid"
      />
    ),
    descriptionLong: (
      <FormattedMessage
        id="analytics-kpi-story-follower-count-paid-explanation"
        description="Publisher Story Follower Count Paid Description"
        defaultMessage="Number of viewers who added your profile through a promotion."
      />
    ),
    formatFunc: privacyNumberFormat,
  },
};

export function getSpotlightAnalyticsGraphConfig(intl: IntlShape & ReactIntl.InjectedIntl) {
  return {
    totalViewers: {
      key: 'totalViewers',
      name: intl.formatMessage({
        id: 'spotlight-total-viewers',
        description: 'Number of viewers for spotlight',
        defaultMessage: 'Unique Viewers',
      }),
      fill: GRAPH_SHADES_OF_BLUE.EXTRA_STRONG_BLUE,
      yAxisTickFormatter: prettyPrintNumber,
      graphTooltip: intl.formatMessage({
        id: 'spotlight-total-viewers-explanation',
        description: 'Number of viewers for spotlight',
        defaultMessage: 'Daily or total unique viewers during the selected timeframe.',
      }),
    },
    totalViews: {
      key: 'totalViews',
      name: intl.formatMessage({
        id: 'spotlight-total-views',
        description: 'Number of views from spotlight content',
        defaultMessage: 'Views',
      }),
      fill: GRAPH_SHADES_OF_BLUE.EXTRA_STRONG_BLUE,
      yAxisTickFormatter: prettyPrintNumber,
      graphTooltip: intl.formatMessage({
        id: 'spotlight-total-views-explanation',
        description: 'Number of views from spotlight content',
        defaultMessage: 'Daily or total unique views during the selected timeframe.',
      }),
    },
    subscribes: {
      key: 'subscribes',
      name: intl.formatMessage({
        id: 'spotlight-new-followers',
        description: 'Number of followers on spotlight',
        defaultMessage: 'Followers Gained',
      }),
      fill: GRAPH_SHADES_OF_BLUE.EXTRA_STRONG_BLUE,
      yAxisTickFormatter: prettyPrintNumber,
      graphTooltip: intl.formatMessage({
        id: 'spotlight-new-followers-explanation',
        description: 'Number of followers on spotlight',
        defaultMessage: 'The total number of followers gained from viewing your Spotlights.',
      }),
    },
    totalViewTime: {
      key: 'totalViewTime',
      name: intl.formatMessage({
        id: 'spotlight-total-view-time',
        description: 'Total number of view time on spotlight content',
        defaultMessage: 'Total View Time',
      }),
      fill: GRAPH_SHADES_OF_BLUE.EXTRA_STRONG_BLUE,
      yAxisTickFormatter: formatTimeViewed,
      graphTooltip: intl.formatMessage({
        id: 'spotlight-total-view-time-explanation',
        description: 'Total number of view time on spotlight content',
        defaultMessage: 'Total amount of time that has been spent viewing your Spotlights.',
      }),
    },
    totalShares: {
      key: 'totalShares',
      name: intl.formatMessage({
        id: 'spotlight-total-shares',
        description: 'Total number of shares of spotlight content',
        defaultMessage: 'Shares',
      }),
      fill: GRAPH_SHADES_OF_BLUE.EXTRA_STRONG_BLUE,
      yAxisTickFormatter: prettyPrintNumber,
      graphTooltip: intl.formatMessage({
        id: 'spotlight-total-shares-explanation',
        description: 'Total number of shares of spotlight content',
        defaultMessage: 'The total number of times your Spotlights have been shared in the time period selected.',
      }),
    },
    totalFavourite: {
      key: 'totalFavourite',
      name: intl.formatMessage({
        id: 'spotlight-favourites',
        description: 'Total number of likes/favourites on spotlight content',
        defaultMessage: 'Likes',
      }),
      fill: GRAPH_SHADES_OF_BLUE.EXTRA_STRONG_BLUE,
      yAxisTickFormatter: prettyPrintNumber,
      graphTooltip: intl.formatMessage({
        id: 'spotlight-favourites-explanation',
        description: 'Total number of favourites on spotlight content',
        defaultMessage: 'The total number of likes your Spotlights have received in the time period selected.',
      }),
    },
  };
}

export function getProfileAnalyticsGraphConfig(intl: IntlShape & ReactIntl.InjectedIntl) {
  return {
    uniqueViewers: {
      key: 'uniqueViewers',
      graphs: [
        {
          key: 'genderUniqueViewers',
          yAxisTickFormatter: prettyPrintNumber,
          name: intl.formatMessage({
            id: 'profile-gender-demographics',
            description: 'Gender Demographic',
            defaultMessage: 'Gender Demographic',
          }),
          graphTooltip: intl.formatMessage({
            id: 'profile-gender-demographics-explanation',
            description: 'Number of unique viewers by gender',
            defaultMessage: 'Number of unique viewers by gender.',
          }),
          config: [
            {
              key: 'male',
              name: intl.formatMessage({
                id: 'gender-male',
                description: 'Grouping by male',
                defaultMessage: 'male',
              }),
              fill: GRAPH_SHADES_OF_BLUE.NORMAL_BLUE,
              yAxisTickFormatter: prettyPrintNumber,
              stackId: 'all',
            },
            {
              key: 'female',
              name: intl.formatMessage({
                id: 'gender-female',
                description: 'Grouping by female',
                defaultMessage: 'female',
              }),
              fill: GRAPH_SHADES_OF_BLUE.EXTRA_STRONG_BLUE,
              stackId: 'all',
            },
            {
              key: 'unknown',
              name: intl.formatMessage({
                id: 'gender-unknown',
                description: 'Grouping by unknown',
                defaultMessage: 'unknown',
              }),
              fill: GRAPH_SHADES_OF_BLUE.EXTRA_LIGHT_BLUE,
              stackId: 'all',
            },
          ],
        },
        {
          key: 'ageUniqueViewers',
          yAxisTickFormatter: prettyPrintNumber,
          name: intl.formatMessage({
            id: 'profile-total-viewers',
            description: 'Number of unique viewers grouped by age',
            defaultMessage: 'Number of unique viewers grouped by age.',
          }),
          graphTooltip: intl.formatMessage({
            id: 'profile-age-demographics-explanation',
            description: 'Number of unique viewers by age',
            defaultMessage: 'Number of unique viewers by age.',
          }),
          config: [
            {
              key: 'viewers13To17',
              name: intl.formatMessage({
                id: 'demographics-age-13-to-17',
                description: 'The age group describing people between 13 years and 17 years',
                defaultMessage: '13 to 17',
              }),
              fill: GRAPH_SHADES_OF_BLUE.NORMAL_BLUE,
              yAxisTickFormatter: prettyPrintNumber,
              stackId: 'all',
            },
            {
              key: 'viewers18To20',
              name: intl.formatMessage({
                id: 'demographics-age-18-to-20',
                description: 'The age group describing people between 18 years and 20 years',
                defaultMessage: '18 to 20',
              }),
              fill: GRAPH_SHADES_OF_BLUE.EXTRA_STRONG_BLUE,
              yAxisTickFormatter: prettyPrintNumber,
              stackId: 'all',
            },
            {
              key: 'viewers21To24',
              name: intl.formatMessage({
                id: 'demographics-age-21-to-24',
                description: 'The age group describing people between 21 years and 24 years',
                defaultMessage: '21 to 24',
              }),
              fill: GRAPH_SHADES_OF_BLUE.STRONG_BLUE,
              yAxisTickFormatter: prettyPrintNumber,
              stackId: 'all',
            },
            {
              key: 'viewers25To34',
              name: intl.formatMessage({
                id: 'demographics-age-25-to-34',
                description: 'The age group describing people between 25 years and 34 years',
                defaultMessage: '25 to 34',
              }),
              fill: GRAPH_SHADES_OF_BLUE.EXTRA_LIGHT_BLUE,
              yAxisTickFormatter: prettyPrintNumber,
              stackId: 'all',
            },
            {
              key: 'viewers35Plus',
              name: intl.formatMessage({
                id: 'demographics-age-35-plus',
                description: 'The age group describing people 35 and over',
                defaultMessage: '35+',
              }),
              yAxisTickFormatter: prettyPrintNumber,
              fill: GRAPH_SHADES_OF_BLUE.MED_BLUE,
              stackId: 'all',
            },
          ],
        },
      ],
    },
    uniqueViewersSubscribers: {
      graphs: [
        {
          key: 'uniqueViewersSubscribers',
          yAxisTickFormatter: prettyPrintNumber,
          name: intl.formatMessage({
            id: 'unique-viewer-subscribers',
            description: 'Unique Viewers (Followers)',
            defaultMessage: 'Unique Viewers (Followers)',
          }),
          graphTooltip: intl.formatMessage({
            id: 'total-unique-viewers-follower-explanation',
            description: 'Total unique viewers who are followers',
            defaultMessage: 'Total unique viewers who are followers',
          }),
          config: [
            {
              key: 'uniqueViewersSubscribers',
              name: intl.formatMessage({
                id: 'unique-viewer-followers-bar',
                description: 'Unique Viewers who are followers',
                defaultMessage: 'Unique Viewers',
              }),
              fill: GRAPH_SHADES_OF_BLUE.EXTRA_STRONG_BLUE,
              yAxisTickFormatter: prettyPrintNumber,
            },
          ],
        },
      ],
    },
    totalTimeViewedMs: {
      graphs: [
        {
          key: 'totalTimeViewedMs',
          yAxisTickFormatter: formatTimeViewed,
          name: intl.formatMessage({
            id: 'profile-total-time-viewed',
            description: 'Total time viewed',
            defaultMessage: 'Total time viewed',
          }),
          graphTooltip: intl.formatMessage({
            id: 'profile-time-viewed-explanation',
            description: 'Total time viewed on profile',
            defaultMessage: 'Total time viewed on profile',
          }),
          config: [
            {
              key: 'totalTimeViewed',
              name: intl.formatMessage({
                id: 'profile-time-viewed-config',
                description: 'Time viewed',
                defaultMessage: 'Time viewed',
              }),
              fill: GRAPH_SHADES_OF_BLUE.EXTRA_STRONG_BLUE,
              yAxisTickFormatter: convertMsToSingleTimeUnit,
            },
          ],
        },
      ],
    },
    totalSubscribers: {
      graphs: [
        {
          key: 'totalSubscribers',
          yAxisTickFormatter: prettyPrintNumber,
          name: intl.formatMessage({
            id: 'profile-total-unique-follower',
            description: 'Total Followers',
            defaultMessage: 'Total unique followers for this profile',
          }),
          graphTooltip: intl.formatMessage({
            id: 'profile-total-unique-follower-explanation',
            description: 'Total Followers',
            defaultMessage: 'Total followers for this profile',
          }),
          config: [
            {
              key: 'totalSubscribers',
              name: intl.formatMessage({
                id: 'profile-unique-follower-viewer',
                description: 'Total Unique Follower Views',
                defaultMessage: 'Total Follower Views',
              }),
              fill: GRAPH_SHADES_OF_BLUE.EXTRA_STRONG_BLUE,
              yAxisTickFormatter: prettyPrintNumber,
            },
          ],
        },
      ],
    },
  };
}

export const PROFILE_ANALYTICS_KPI__CONFIG = [
  {
    metricId: 'uniqueViewers',
    name: (
      <FormattedMessage
        id="profile-unique-viewers"
        description="Number of unique viewers for all content types posted to (Shows, Spotlight, Lenses etc)"
        defaultMessage="Unique Viewers"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="profile-unique-viewers-explanation"
        description="Total unique viewers for all content types posted to"
        defaultMessage="The total unique viewers for all content types you post to (Shows, Spotlight, Lenses etc). Viewers is displayed for UTC timezone."
      />
    ),
    defaultMessageId: 'profile-unique-viewers',
    formatFunc: privacyNumberFormat,
    previousValueDescription: weekOverWeek,
    tabSize: 5,
  },
  {
    metricId: 'uniqueViewersSubscribers',
    name: (
      <FormattedMessage
        id="profile-unique-viewers-followers"
        description="Number of unique viewers for all content types posted to who are followers"
        defaultMessage="Unique Viewers (Followers)"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="profile-unique-viewers-followers-explanation"
        description="Number of unique viewers for all content types posted to who are followers"
        defaultMessage="The total unique viewers for all content types you post to (Shows, Spotlight, Lenses etc) who are followers."
      />
    ),
    defaultMessageId: 'profile-unique-viewers-followers',
    formatFunc: privacyNumberFormat,
    previousValueDescription: weekOverWeek,
    tabSize: 5,
  },
  {
    metricId: 'totalTimeViewedMs',
    name: (
      <FormattedMessage
        id="profile-total-time-viewed"
        description="Total time viewed for all content types posted to"
        defaultMessage="Time Viewed"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="profile-total-time-viewed-explanation"
        description="Total view time for all content types posted to"
        defaultMessage="Total view time for all content types posted to (Shows, Spotlight, Lenses etc)"
      />
    ),
    defaultMessageId: 'profile-total-time-viewed',
    formatFunc: formatTimeViewed,
    previousValueDescription: rangeOverRange,
    tabSize: 5,
  },
  {
    metricId: 'totalSubscribers',
    name: (
      <FormattedMessage
        id="profile-followers"
        description="Total number of followers in profile"
        defaultMessage="Followers"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="profile-followers-explanation"
        description="Followers"
        defaultMessage="Total number of followers for this profile"
      />
    ),
    defaultMessageId: 'profile-followers',
    formatFunc: privacyNumberFormat,
    previousValueDescription: rangeOverRange,
    tabSize: 5,
  },
] as const;

export const STORIES_ANALYTICS_KPI__CONFIG = [
  {
    metricId: 'uniqueViewers',
    name: (
      <FormattedMessage
        id="stories-unique-viewers"
        description="Number of unique viewers for all stories"
        defaultMessage="Unique Viewers"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="stories-unique-viewers-explanation"
        description="Number of unique viewers for stories"
        defaultMessage="The total daily unique viewers of your Stories. Viewers is displayed in UTC timezone."
      />
    ),
    defaultMessageId: 'stories-unique-viewers',
    formatFunc: privacyNumberFormat,
    previousValueDescription: weekOverWeek,
    tabSize: 4,
  },
  {
    metricId: 'timeSpentMs',
    name: (
      <FormattedMessage
        id="stories-time-spent"
        description="Total time spent on all stories"
        defaultMessage="Time Spent"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="stories-time-spent-explanation"
        description="Total time spent on all stories"
        defaultMessage="Total time spent on all stories"
      />
    ),
    defaultMessageId: 'profile-unique-viewers-subscribers',
    formatFunc: privacyNumberFormat,
    previousValueDescription: weekOverWeek,
    tabSize: 4,
  },
  {
    metricId: 'timeViewedMs',
    name: (
      <FormattedMessage
        id="profile-total-time-viewed"
        description="Total time viewed for profile"
        defaultMessage="Time Viewed"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="profile-time-viewed-explanation"
        description="Total view time on content from profile"
        defaultMessage="Total view time on content from profile."
      />
    ),
    defaultMessageId: 'profile-total-time-viewed',
    formatFunc: formatTimeViewed,
    previousValueDescription: rangeOverRange,
    tabSize: 4,
  },
  {
    metricId: 'screenshots',
    name: (
      <FormattedMessage
        id="stories-screenshot"
        description="Total screenshots taken on all stories"
        defaultMessage="Screenshots"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="stories-screenshots"
        description="Stories screenshots"
        defaultMessage="Total screenshots taken on all stories"
      />
    ),
    defaultMessageId: 'stories-screenshot',
    formatFunc: privacyNumberFormat,
    previousValueDescription: rangeOverRange,
    tabSize: 4,
  },
  {
    metricId: 'shares',
    name: <FormattedMessage id="stories-shares" description="Total shares on all stories" defaultMessage="Shares" />,
    tooltip: (
      <FormattedMessage id="stories-shares" description="Stories shares" defaultMessage="Total shares on all stories" />
    ),
    defaultMessageId: 'stories-shares',
    formatFunc: privacyNumberFormat,
    previousValueDescription: rangeOverRange,
    tabSize: 4,
  },
  {
    metricId: 'totalStoryViews',
    name: (
      <FormattedMessage
        id="stories-stories-views"
        description="Total number of views on all stories"
        defaultMessage="Stories Views"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="stories-stories-views"
        description="Stories views"
        defaultMessage="Total number of views on all stories"
      />
    ),
    defaultMessageId: 'stories-stories-views',
    formatFunc: privacyNumberFormat,
    previousValueDescription: rangeOverRange,
    tabSize: 4,
  },
  {
    metricId: 'attachmentConversion',
    name: (
      <FormattedMessage
        id="stories-attachment-conversion"
        description="Total attachment conversion on all stories"
        defaultMessage="Attachment Conversion"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="stories-attachment-conversion"
        description="Stories attachment conversion"
        defaultMessage="Total attachment conversion on all stories"
      />
    ),
    defaultMessageId: 'stories-attachment-conversion',
    formatFunc: privacyNumberFormat,
    previousValueDescription: rangeOverRange,
    tabSize: 4,
  },
] as const;

export const SPOTLIGHT_ANALYTICS_KPI__CONFIG = [
  {
    metricId: 'totalViewers',
    name: (
      <FormattedMessage
        id="spotlight-unique-viewers"
        description="Number of unique viewers for spotlight"
        defaultMessage="Unique Viewers"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="spotlight-unique-viewers-explanation"
        description="Number of unique viewers for spotlight"
        defaultMessage="The total daily unique viewers of your Spotlights. Spotlight Viewers is displayed in UTC timezone."
      />
    ),
    defaultMessageId: 'spotlight-total-viewers',
    formatFunc: privacyNumberFormat,
    previousValueDescription: weekOverWeek,
  },
  {
    metricId: 'totalViews',
    name: (
      <FormattedMessage
        id="spotlight-total-views"
        description="Number of views from spotlight content"
        defaultMessage="Views"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="spotlight-total-views-explanation"
        description="Number of views from spotlight content"
        defaultMessage="The total number of daily views of your Spotlights. Spotlight Views is displayed in UTC timezone."
      />
    ),
    defaultMessageId: 'spotlight-total-views',
    formatFunc: privacyNumberFormat,
    previousValueDescription: rangeOverRange,
  },
  {
    metricId: 'subscribes',
    name: (
      <FormattedMessage
        id="spotlight-followers-gained"
        description="Number of followers on spotlight"
        defaultMessage="Followers Gained"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="spotlight-followers-gained-explanation"
        description="Number of followers on spotlight"
        defaultMessage="The total number of followers gained from viewing your Spotlights."
      />
    ),
    defaultMessageId: 'spotlight-new-followers',
    formatFunc: privacyNumberFormat,
    previousValueDescription: rangeOverRange,
  },
  {
    metricId: 'totalViewTime',
    name: (
      <FormattedMessage
        id="spotlight-total-view-time"
        description="Total number of view time on spotlight content"
        defaultMessage="Total View Time"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="spotlight-total-view-time-explanation"
        description="Total number of view time on spotlight content"
        defaultMessage="Total amount of time that has been spent viewing your Spotlights."
      />
    ),
    defaultMessageId: 'spotlight-total-view-time',
    formatFunc: formatTimeViewed,
    previousValueDescription: rangeOverRange,
  },
  {
    metricId: 'totalShares',
    name: (
      <FormattedMessage
        id="spotlight-total-shares"
        description="Total number of shares of spotlight content"
        defaultMessage="Shares"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="spotlight-total-shares-explanation"
        description="Total number of shares of spotlight content"
        defaultMessage="The total number of times your Spotlights have been shared in the time period selected."
      />
    ),
    defaultMessageId: 'spotlight-total-shares',
    formatFunc: privacyNumberFormat,
    previousValueDescription: rangeOverRange,
  },
  {
    metricId: 'totalFavourite',
    name: (
      <FormattedMessage
        id="spotlight-favorites"
        description="Total number of favorites on spotlight content"
        defaultMessage="Favorites"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="spotlight-favorites-explanation"
        description="Total number of favorites on spotlight content"
        defaultMessage="The total number of favorites your Spotlights have received in the time period selected."
      />
    ),
    defaultMessageId: 'spotlight-favorites',
    formatFunc: privacyNumberFormat,
    previousValueDescription: rangeOverRange,
  },
] as const;

export enum AnalyticsReportRange {
  WEEK = 7,
  MONTH = 28,
}

type SpotlightMetricMapValue = Record<AnalyticsReportRange, keyof SpotlightSummary>;

type SpotlightMetricMap = {
  [key in SpotlightMetric]: {
    currentMetrics: SpotlightMetricMapValue;
    previousMetrics: SpotlightMetricMapValue;
  };
};

type ProfileMetricMap = {
  [key in ProfileMetric]: {
    currentMetrics: string;
    previousMetrics: string;
  };
};

type StoriesMetricMap = {
  [key in StoriesMetric]: {
    currentMetrics: string;
    previousMetrics: string;
  };
};

export const spotlightMetricMap: SpotlightMetricMap = {
  totalViewTime: {
    currentMetrics: {
      [AnalyticsReportRange.WEEK]: 'totalViewTimeSinceLastWeek',
      [AnalyticsReportRange.MONTH]: 'totalViewTimeSinceLastMonth',
    },
    previousMetrics: {
      [AnalyticsReportRange.WEEK]: 'totalViewTimeSincePreviousWeek',
      [AnalyticsReportRange.MONTH]: 'totalViewTimeSincePreviousMonth',
    },
  },
  totalViewers: {
    currentMetrics: {
      [AnalyticsReportRange.WEEK]: 'totalViewersSinceLastWeek',
      [AnalyticsReportRange.MONTH]: 'totalViewersSinceLastMonth',
    },
    previousMetrics: {
      [AnalyticsReportRange.WEEK]: 'totalViewersSincePreviousWeek',
      [AnalyticsReportRange.MONTH]: 'totalViewersSincePreviousMonth',
    },
  },
  totalViews: {
    currentMetrics: {
      [AnalyticsReportRange.WEEK]: 'totalViewsSinceLastWeek',
      [AnalyticsReportRange.MONTH]: 'totalViewsSinceLastMonth',
    },
    previousMetrics: {
      [AnalyticsReportRange.WEEK]: 'totalViewsSincePreviousWeek',
      [AnalyticsReportRange.MONTH]: 'totalViewsSincePreviousMonth',
    },
  },
  subscribes: {
    currentMetrics: {
      [AnalyticsReportRange.WEEK]: 'subscribesSinceLastWeek',
      [AnalyticsReportRange.MONTH]: 'subscribesSinceLastMonth',
    },
    previousMetrics: {
      [AnalyticsReportRange.WEEK]: 'subscribesSincePreviousWeek',
      [AnalyticsReportRange.MONTH]: 'subscribesSincePreviousMonth',
    },
  },
  totalShares: {
    currentMetrics: {
      [AnalyticsReportRange.WEEK]: 'totalSharesSinceLastWeek',
      [AnalyticsReportRange.MONTH]: 'totalSharesSinceLastMonth',
    },
    previousMetrics: {
      [AnalyticsReportRange.WEEK]: 'totalSharesSincePreviousWeek',
      [AnalyticsReportRange.MONTH]: 'totalSharesSincePreviousMonth',
    },
  },
  totalFavourite: {
    currentMetrics: {
      [AnalyticsReportRange.WEEK]: 'totalFavouritesSinceLastWeek',
      [AnalyticsReportRange.MONTH]: 'totalFavouritesSinceLastMonth',
    },
    previousMetrics: {
      [AnalyticsReportRange.WEEK]: 'totalFavouritesSincePreviousWeek',
      [AnalyticsReportRange.MONTH]: 'totalFavouritesSincePreviousMonth',
    },
  },
};

export const profileMetricMap: ProfileMetricMap = {
  uniqueViewers: {
    currentMetrics: 'uniqueViewers',
    previousMetrics: 'uniqueViewersPreviousPeriod',
  },
  uniqueViewersSubscribers: {
    currentMetrics: 'uniqueViewersSubscribers',
    previousMetrics: 'uniqueViewersSubscribersPreviousPeriod',
  },
  totalTimeViewedMs: {
    currentMetrics: 'totalTimeViewedMs',
    previousMetrics: 'totalTimeViewedPreviousPeriodMs',
  },
  totalSubscribers: {
    currentMetrics: 'totalSubscribers',
    previousMetrics: 'totalSubscribersPreviousPeriod',
  },
};

export const storiesMetricMap: StoriesMetricMap = {
  uniqueViewers: {
    currentMetrics: 'uniqueViewers',
    previousMetrics: 'uniqueViewersPreviousPeriod',
  },
  timeSpentMs: {
    currentMetrics: 'timeSpentMs',
    previousMetrics: 'timeSpentMsPreviousPeriod',
  },
  timeViewedMs: {
    currentMetrics: 'timeViewedMs',
    previousMetrics: 'timeViewedMsPreviousPeriod',
  },
  screenshots: {
    currentMetrics: 'screenshots',
    previousMetrics: 'screenshotsPreviousPeriod',
  },
  shares: {
    currentMetrics: 'shares',
    previousMetrics: 'sharesPreviousPeriod',
  },
  totalStoryViews: {
    currentMetrics: 'totalStoryViews',
    previousMetrics: 'totalStoryViewsPreviousPeriod',
  },
  attachmentConversion: {
    currentMetrics: 'attachmentConversion',
    previousMetrics: 'attachmentConversionPreviousPeriod',
  },
};

EDITION_KPI_CONFIG.forEach(attachFindValue);
EDITION_KPI_CONFIG_V2.forEach(attachFindValue);
DAILY_KPI_V3_CONFIG.forEach(attachFindValue);
SEGMENT_KPI_CONFIG.forEach(attachFindValue);
SEGMENT_TILES_KPI_CONFIG.forEach(attachFindValue);
PAY_TO_PROMOTE_KPI_CONFIG_ADS_MANAGER.forEach(attachFindValue);
PAY_TO_PROMOTE_KPI_CONFIG_STORY_STUDIO.forEach(attachFindValue);

function percentChangedForPercentValues(value: any, previousValue: any) {
  return value - previousValue;
}

function avgSubtitle({ startDate, endDate, minDate, dateFormat, totalDays }: any) {
  return `${totalDays} DAY AVG`;
}

function totalSubtitle({ startDate, endDate, minDate, dateFormat, totalDays }: any) {
  return `${totalDays} DAY TOTAL`;
}

function weekOverWeek({ startDate, endDate, minDate, dateFormat, totalDays }: any) {
  return (
    <FormattedMessage
      id="week-over-week-subtitle"
      description="Week Over Week Subtitle"
      defaultMessage="vs previous 7 day avg"
    />
  );
}

function rangeOverRange({ startDate, endDate, minDate, dateFormat, totalDays }: any) {
  return (
    <FormattedMessage
      id="range-over-range-subtitle"
      description="Range Over Range Subtitle"
      defaultMessage="VS. PRIOR {totalDays} DAYS"
      values={{ totalDays }}
    />
  );
}

export function rangeOverAvg(totalDays: number) {
  return (
    <FormattedMessage
      id="range-over-avg-subtitle"
      description="Range Over Average Subtitle"
      defaultMessage="vs past {totalDays} days avg"
      values={{ totalDays }}
    />
  );
}

function weekOverWeekLong({ startDate, endDate, minDate, dateFormat, totalDays }: any) {
  return (
    <FormattedMessage
      id="week-over-week-subtitle-long"
      description="Week Over Week Subtitle Long"
      defaultMessage="{startDate} - {endDate} average compared to the previous 7 day average"
      values={{
        startDate: dateFormat(startDate),
        endDate: dateFormat(endDate),
        totalDays,
      }}
    />
  );
}

function rangeOverRangeLong({ startDate, endDate, minDate, dateFormat, totalDays }: any) {
  return (
    <FormattedMessage
      id="range-over-range-subtitle-long"
      description="Range Over Range Subtitle Long"
      defaultMessage="{startDate} - {endDate} average compared to the previous {totalDays} days"
      values={{
        startDate: dateFormat(startDate),
        endDate: dateFormat(endDate),
        totalDays,
      }}
    />
  );
}

const subtitlePropTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  minDate: PropTypes.bool,
  dateFormat: PropTypes.func,
  totalDays: PropTypes.number,
};

weekOverWeek.prototype.propTypes = subtitlePropTypes;
rangeOverRange.prototype.propTypes = subtitlePropTypes;
weekOverWeekLong.prototype.propTypes = subtitlePropTypes;
rangeOverRangeLong.prototype.propTypes = subtitlePropTypes;
