import React from 'react';

import { fetchDebugSnap } from 'state/debug/debugActions';
import { debugSnapEndpoint } from 'state/debug/debugApis';

import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { withRouter } from 'utils/routerUtils';

import ClaimGatedView from 'views/common/containers/ClaimGatedView/ClaimGatedView';
import { DebugPage } from 'views/debug/components/DebugPage';
import {
  formatDate,
  transformToDiffedAuditRecords,
  transformDiffedAuditRecordsForDebug,
} from 'views/debug/utils/debugPageUtils';

import style from './DebugSnapView.scss';

import type { SnapId } from 'types/common';
import { Claim } from 'types/permissions';
import { SnapRelationship } from 'types/snaps';

function transformData({ snap, relatedSnaps, auditRecords, allBuilds }: any) {
  const diffedAuditRecords = transformToDiffedAuditRecords(auditRecords);
  return [
    { title: 'Snap', subsections: transformSnapInfo(snap) },
    { title: 'Related Snaps', subsections: transformRelatedSnaps(relatedSnaps) },
    { title: 'Assets', subsections: transformAssets(snap) },
    { title: 'Builds', subsections: transformBuilds(allBuilds) },
    { title: 'Audit Records', subsections: transformDiffedAuditRecordsForDebug(diffedAuditRecords) },
  ];
}

function transformSnapInfo(snap: any) {
  const { id, name, type, createdAt, lastUpdatedAt, videoAsset, imageAsset } = snap;

  const asset = (videoAsset && videoAsset.path1 && videoAsset) || (imageAsset && imageAsset.path1 && imageAsset);

  const storage = asset && asset.storage;
  const bucket = storage && storage.bucket;
  const path =
    asset &&
    ['path1', 'path2', 'path3']
      .map(key => asset[key])
      .filter(x => x)
      .join('/');

  const href = bucket && path ? `https://console.cloud.google.com/storage/browser/${bucket}/${path}` : '#';

  return [
    [
      ['Id', id],
      ['Name', name],
      ['Created At', formatDate(createdAt)],
      ['Last Updated At', formatDate(lastUpdatedAt)],
      ['Type', type],
      ['GCS Folder', { type: 'link', href }],
      ['Raw', { type: 'json', object: snap, isExpanded: (keypath: any) => false }],
    ],
  ];
}

function unrecognizedMedia(obj: any) {
  return { type: 'unrecognized', raw: obj };
}

function transformAssets(snap: any) {
  const assets = [];

  if (snap.imageAsset) {
    assets.push([
      ['Image Asset Id', snap.imageAssetId],
      ['Image Asset Preview', transformImage(snap.imageAsset)],
    ]);
  }

  if (snap.videoAsset) {
    assets.push([
      ['Video Asset Id', snap.videoAssetId],
      ['Video Asset Preview', transformVideo(snap.videoAsset)],
    ]);
  }

  if (snap.overlayImageAsset) {
    assets.push([
      ['Overlay Image Asset Id', snap.overlayImageAssetId],
      ['Overlay Image Asset Preview', transformImage(snap.overlayImageAsset)],
    ]);
  }

  if (snap.longformVideoAsset) {
    assets.push([
      ['Longform Video Asset Id', snap.longformVideoAssetId],
      // TODO Support longform video previews
    ]);
  }

  return assets;
}

function transformVideo(asset: any) {
  const { storage } = asset;
  switch (storage.type) {
    case 'GCS': {
      const { bucket, filename } = storage;
      const src = `https://storage.cloud.google.com/${bucket}/${filename}`;
      return { type: 'video', src };
    }
    default:
      return unrecognizedMedia(storage);
  }
}

function transformImage(asset: any) {
  const { storage } = asset;
  switch (storage.type) {
    case 'GCS': {
      const { bucket, filename } = storage;
      const src = `https://storage.cloud.google.com/${bucket}/${filename}`;
      return { type: 'image', src };
    }
    default:
      return unrecognizedMedia(storage);
  }
}

function transformRelatedSnaps(relatedSnaps: any) {
  return [
    [
      ['Top Snap', transformRelatedSnap(relatedSnaps[SnapRelationship.TOP])],
      ['Bottom Snap', transformRelatedSnap(relatedSnaps[SnapRelationship.BOTTOM])],
    ],
  ];
}

function transformRelatedSnap(relatedSnap: any) {
  if (relatedSnap) {
    return { type: 'link', href: `/v2/debug/snap/${relatedSnap.id}`, label: relatedSnap.id };
  }

  return undefined;
}

function transformBuilds(allBuilds: any) {
  return allBuilds
    .map((build: any) => {
      return [
        ['Status', build.status],
        ['Type', build.type],
        ['Id', build.id],
        ['Hash', build.buildHash],
        ['Created At', formatDate(build.createdAt)],
        ['Last Updated At', formatDate(build.lastUpdatedAt)],
        ['Queued At', formatDate(build.queuedAt)],
        ['Running At', formatDate(build.runningAt)],
        ['Finished At', formatDate(build.finishedAt)],
        ['Latest Changed At', formatDate(build.latestChangedAt)],
        ['Raw', { type: 'json', object: build, isExpanded: (keypath: any) => false }],
      ];
    })
    .reverse();
}

const mapStateToProps = (state: State, ownProps: any) => {
  const { snapId } = ownProps.match.params;
  const { debugSnapsById } = state.debug; // eslint-disable-line no-shadow
  const debugSnapData = debugSnapsById[snapId];

  return {
    sections: debugSnapData && transformData(debugSnapData),
  };
};

const mapDispatchToProps = {
  fetchDebugSnap,
};

type Props = {
  fetchDebugSnap: (snapId: SnapId) => any;
  match: any;
  sections: any;
};

/* eslint-disable react/prop-types */

class DebugSnapView extends React.Component<Props> {
  UNSAFE_componentWillMount() {
    const { props } = this;
    const { snapId } = props.match.params;
    props.fetchDebugSnap(snapId);
  }

  render() {
    const { match, sections } = this.props;
    const { snapId } = match.params;

    return (
      <div className={style.root}>
        <ClaimGatedView requiredClaim={Claim.DEBUG_ENTITY_VIEWER}>
          <DebugPage sections={sections} type="Snap" id={snapId} href={debugSnapEndpoint(snapId)} />
        </ClaimGatedView>
      </div>
    );
  }
}

export default withRouter(intlConnect(mapStateToProps, mapDispatchToProps)(DebugSnapView));
