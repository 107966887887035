import React from 'react';

import { help } from 'icons/SDS/allIcons';

import Icon from 'views/common/components/Icon/Icon';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';

import style from './DebugSDSTooltips.scss';

function DebugSDSTooltips() {
  return (
    <>
      <div className={style.wrapper}>
        <p>Tooltip - Position TOP</p>
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        <SDSTooltip placement={TooltipPosition.TOP} title="Tooltip" id="tooltip">
          <Icon inlineIcon={help} className={style.infoIcon} />
        </SDSTooltip>
      </div>
      <div className={style.wrapper}>
        <p>Tooltip - Position BOTTOM</p>
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        <SDSTooltip placement={TooltipPosition.BOTTOM} title="Tooltip" id="tooltip">
          <Icon inlineIcon={help} className={style.infoIcon} />
        </SDSTooltip>
      </div>
      <div className={style.wrapper}>
        <p>Tooltip - Position RIGHT</p>
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        <SDSTooltip placement={TooltipPosition.RIGHT} title="Tooltip" id="tooltip">
          <Icon inlineIcon={help} className={style.infoIcon} />
        </SDSTooltip>
      </div>
    </>
  );
}

export default DebugSDSTooltips;
