import React, { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { getDisplayMessageForLanguageCode } from 'constants/subtitles';

import DeleteButton from 'views/common/components/DeleteButton/DeleteButton';

type Props = {
  onDelete: (a: string) => void;
  languageCode: string;
  isDeleting: boolean;
};

function DeleteSubtitlesButton({ onDelete, languageCode, isDeleting }: Props) {
  const handleOnDelete = useCallback(() => {
    onDelete(languageCode);
  }, [onDelete, languageCode]);

  return (
    <DeleteButton
      purpose={`delete-subtitle-${languageCode}`}
      onDelete={handleOnDelete}
      isDeleting={isDeleting}
      alertMessage={
        <FormattedMessage
          id="SubtitlesModal.delete.body"
          description="Prompt displayed when we try to delete subtitles."
          defaultMessage="Are you sure you want to delete subtitles: {languageCode} for the whole story?"
          values={{ languageCode: getDisplayMessageForLanguageCode(languageCode) }}
        />
      }
    />
  );
}

export default memo(DeleteSubtitlesButton);
