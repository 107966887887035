import React from 'react';

import style from '../DebugIcons/DebugIcons.scss';

import * as Icons from 'icons/SDS/allIcons';

import Icon from 'views/common/components/Icon/Icon';

function DebugSDSIcons() {
  const sortedIcons = {};

  Object.keys(Icons)
    .sort()
    .forEach(key => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      sortedIcons[key] = Icons[key];
    });

  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const iconsWithNames = Object.keys(sortedIcons).map((name, index) => renderIcon(name, sortedIcons[name], index));

  function sanitizeIconName(name: any) {
    return name.split('/').slice(-1)[0].split('.')[0];
  }

  function renderIcon(name: any, icon: any, index: any) {
    return (
      <div className={style.cell} key={index}>
        <Icon inlineIcon={icon} />
        <div className={style.cellTitle}>{sanitizeIconName(name)}</div>
      </div>
    );
  }

  return <div className={style.body}>{iconsWithNames}</div>;
}

export default DebugSDSIcons;
