import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import * as editionsSelectors from 'state/editions/selectors/editionsSelectors';
import * as mediaSelectors from 'state/media/selectors/mediaSelectors';

import { CrossOrigin, EMPTY_OBJECT } from 'config/constants';

import LoadingView from 'views/common/components/LoadingView/LoadingView';

import style from './NewStoryView.scss';

import type { State as ReduxState } from 'types/rootState';

const NewStoryView = React.memo(() => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'firstFrameKey' does not exist on type 'R... Remove this comment to see the full error message
  const { firstFrameKey, title } = useSelector(
    (state: ReduxState) => editionsSelectors.getNewStoryInfo(state) || EMPTY_OBJECT
  );
  const frameResult = useSelector((state: ReduxState) => mediaSelectors.getFrameResultById(state)(firstFrameKey));
  if (frameResult) {
    return (
      <div className={style.root}>
        <div className={style.message}>
          <FormattedMessage
            id="new-story-message"
            description="Shown on the loading screen when new story is being created"
            defaultMessage="Creating Your New Story"
          />
        </div>
        <img
          className={style.firstFrame}
          crossOrigin={CrossOrigin.USE_CREDENTIALS}
          src={(frameResult as any).result}
          alt="first frame"
        />
        <div className={style.title}>{title}</div>
      </div>
    );
  }
  return <LoadingView messageId="loading-message-story" />;
});
export default NewStoryView;
