import React from 'react';

import { isGoogleAuthLoading } from 'state/auth/selectors/authSelectors';
import { getQuery } from 'state/router/selectors/routerSelectors';
import type { Query } from 'state/router/selectors/routerSelectors';

import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';

import GoogleSignInButton from 'views/auth/GoogleSignInButton/GoogleSignInButton';
import LoginErrorView from 'views/auth/LoginErrorView/LoginErrorView';
import GhostNavBarContainer from 'views/common/GhostNavBarContainer/GhostNavBarContainer';

import style from './LoginWithGoogle.scss';

type StateProps = {
  query: Query;
  isLoading: boolean;
};

type Props = StateProps;

export const mapStateToProps = (state: State) => {
  return {
    query: getQuery(state),
    isLoading: isGoogleAuthLoading(state),
  };
};

export class LoginWithGoogle extends React.Component<Props> {
  render() {
    return (
      <GhostNavBarContainer>
        <div className={style.rooting}>
          <div className={style.centered}>
            <div className={style.content}>
              <GoogleSignInButton />
              {this.props.isLoading ? null : (
                <LoginErrorView error={this.props.query.error || this.props.query.message} />
              )}
            </div>
          </div>
        </div>
      </GhostNavBarContainer>
    );
  }
}

export default intlConnect(mapStateToProps, null)(LoginWithGoogle);
